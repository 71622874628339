import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../../assets/logo/logo.png";
import "./Navbar.css";

const Navbar = () => {
  const location = useLocation(); // Utilisez useLocation pour obtenir l'emplacement actuel

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Déclenchez le défilement lorsque l'emplacement change

  // Smooth State
  const closeMenu = () => setClick(false);

  // Hamburger State
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  // color State
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  // Active Scroll Navbar
  window.addEventListener("scroll", changeColor);

  // Function to check if a link is active
  const isActive = (path) => location.pathname === path;

  return (
    <div className={color ? "bg-transparent header header-bg" : "header"}>
      <img className="w-[150px] pt-[50px]" src={Logo} alt="" />
      <ul className={click ? "nav-menu active bg-custom" : "nav-menu"}>
        <li>
          <Link
            className={`nav-item font-oswald ${isActive("/") ? "active" : ""}`}
            to="/"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onClick={closeMenu}
          >
            ACCUEIL
          </Link>
        </li>
        <li>
          <Link
            className={`nav-item font-oswald ${
              isActive("/biography") ? "active" : ""
            }`}
            to="/biography"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onClick={closeMenu}
          >
            BIOGRAPHIE
          </Link>
        </li>
        <li>
          <Link
            className={`nav-item font-oswald ${
              isActive("/repertory") ? "active" : ""
            }`}
            to="/repertory"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onClick={closeMenu}
          >
            RÉPERTOIRE
          </Link>
        </li>
        <li>
          <Link
            className={`nav-item font-oswald ${
              isActive("/concerts") ? "active" : ""
            }`}
            to="/concerts"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onClick={closeMenu}
          >
            CONCERTS
          </Link>
        </li>
        <li>
          <Link
            className={`nav-item font-oswald ${
              isActive("/discography") ? "active" : ""
            }`}
            to="/discography"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onClick={closeMenu}
          >
            DISCOGRAPHIE
          </Link>
        </li>
        <li>
          <Link
            className={`nav-item font-oswald ${
              isActive("/medias") ? "active" : ""
            }`}
            to="/medias"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onClick={closeMenu}
          >
            MÉDIAS
          </Link>
        </li>

        <li>
          <Link
            className={`nav-item font-oswald ${
              isActive("/contact") ? "active" : ""
            }`}
            to="/contact"
            spy="true"
            smooth="true"
            offset={-100}
            duration={500}
            onClick={closeMenu}
          >
            CONTACT
          </Link>
        </li>
      </ul>

      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={20} style={{ color: "#fff" }} />
        ) : (
          <FaBars size={20} style={{ color: "#fff" }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
