import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="p-4 bg-black sm:p-6 dark ">
      <div className="mx-auto max-w-screen-xl text-center">
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-center">
          <div className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            <span>© 2024 </span>
            <p className="hover:underline text-gray-400 ">Cécile Ousset</p>Tous
            droits réservés.
          </div>
          <div className="flex flex-col pt-5 pl-4">
            <div className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              <Link to="/termsofuse" className="hover:underline">
                Mentions légales
              </Link>
            </div>
            <div className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              <Link to="/privacypolicy" className="hover:underline">
                Politique de confidentialité
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
