import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <section className="section bg-black h-[100%]" id="biography">
        <div className="pt-[60px] flex flex-col items-center mx-auto">
          <h1 className="text-white max-sm:text-[20px] text-[30px] max-lg:text-[28px]">
            POLITIQUE DE CONFIDENTIALITÉ
          </h1>
          <div className="pt-[70px] text-justify text-black">
            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto max-lg:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5 max-sm:text-[20px]">
                1. Introduction
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 max-sm:text-start">
                La présente politique de confidentialité explique comment nous
                collectons, utilisons et protégeons vos données personnelles
                lorsque vous utilisez notre site <strong>Cécile Ousset</strong>.
                <br />
                Contact :{" "}
                <a href="mailto:thomaschazalpro@gmail.com">
                  thomaschazalpro@gmail.com
                </a>
              </p>
            </div>

            <div className="max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold text-white font-primary text-[25px] pt-10 pb-5 max-sm:text-[20px]">
                2. Données collectées
              </h4>
              <p className="max-w-[700px] text-white pt-5 leading-9 max-sm:text-start">
                Nous pouvons collecter les informations suivantes lorsque vous
                utilisez notre site :
                <ul className="list-disc pl-5">
                  <li>
                    Adresse e-mail (fournie volontairement via le formulaire de
                    contact)
                  </li>
                  <li>
                    Informations de navigation (adresse IP, type de navigateur,
                    pages visitées, etc.)
                  </li>
                </ul>
              </p>
            </div>

            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5 max-sm:text-[20px] max-sm:text-start">
                3. Utilisation des données
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 leading-9 max-sm:text-start">
                Les données collectées sont utilisées pour :
                <ul className="list-disc pl-5">
                  <li>
                    Répondre aux demandes effectuées via le formulaire de
                    contact
                  </li>
                  <li>Améliorer votre expérience utilisateur sur notre site</li>
                  <li>Analyser et comprendre l'utilisation de notre site</li>
                </ul>
              </p>
            </div>

            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5  max-sm:text-[20px] max-sm:text-start">
                4. Partage des données
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 leading-9 max-sm:text-start">
                Nous ne partageons pas vos données personnelles avec des tiers,
                sauf si cela est nécessaire pour fournir nos services ou si nous
                sommes tenus de le faire par la loi.
              </p>
            </div>

            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold text-white font-primary text-[25px] pt-10 pb-5 max-sm:text-[20px]">
                5. Protection des données
              </h4>

              <p className="max-w-[700px] text-justify text-white pt-5 max-sm:text-start">
                Nous prenons des mesures raisonnables pour protéger vos données
                personnelles contre la perte, le vol et l'accès non autorisé.
                Toutefois, aucune méthode de transmission sur Internet ou de
                stockage électronique n'est totalement sécurisée.
              </p>
            </div>

            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5 max-sm:text-[20px]">
                6. Vos droits
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 max-sm:text-start">
                Conformément à la loi « informatique et libertés », vous
                disposez d'un droit d'accès, de rectification, de modification
                et de suppression concernant les données qui vous concernent.
                <br /> Vous pouvez exercer ce droit en envoyant un email à{" "}
                <a href="mailto:thomaschazalpro@gmail.com">
                  thomaschazalpro@gmail.com
                </a>
                .
              </p>
            </div>

            <div className="flex flex-col  max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5 max-sm:text-[20px] max-sm:text-start">
                7. Modifications de la politique de confidentialité
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 max-sm:text-start">
                Nous nous réservons le droit de modifier cette politique de
                confidentialité à tout moment. Toute modification sera publiée
                sur cette page et prendra effet immédiatement. Nous vous
                encourageons à consulter régulièrement cette page pour être
                informé de tout changement.
              </p>
            </div>

            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5 max-sm:text-[20px]">
                8. Contact
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 max-sm:text-start">
                Si vous avez des questions concernant cette politique de
                confidentialité, veuillez nous contacter à :{" "}
                <a href="mailto:thomaschazalpro@gmail.com">
                  thomaschazalpro@gmail.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
