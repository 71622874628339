import React, { useRef, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import ImgContact from "../../assets/about/contact.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
  // Fonction pour empêcher les actions de copier, coller et glisser-déposer
  const preventDefaultActions = (e) => {
    e.preventDefault();
  };

  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false); // Nouvel état pour gérer l'état du bouton

  const sendEmail = (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true); // Désactiver le bouton dès que le formulaire est soumis
      emailjs
        .sendForm(
          "service_c70ocwp",
          "template_4h1v8s1",
          form.current,
          "6AfzbypZEJtpZ_K8g"
        )
        .then(
          (result) => {
            console.log(result.text);
            toast.success(
              "Nous vous remercions pour votre message qui sera traité dans les meilleurs délais.",
              {
                autoClose: 3000,
              }
            );
            setTimeout(() => {
              document.location.href = "/";
            }, 3000);
          },
          (error) => {
            console.log(error.text);
            setIsSubmitting(false); // Réactiver le bouton en cas d'erreur
          }
        );
    }
  };

  return (
    <>
      <Navbar />
      <section
        className="h-full bg-black pt-[60px] max-lg:h-[86vh] max-lg:min-h-[90vh] overflow-hidden max-sm:h-full max-lg:pt-[70px]"
        id="contact"
      >
        <div className="flex flex-col pt-[60px]">
          <h1 className="text-center max-sm:text-[30px]">CONTACT</h1>

          <div className="flex justify-center mb-[100px] items-center gap-[150px] pt-[70px] max-sm:flex-col max-sm:gap-0 max-sm:pt-10 max-lg:gap-10">
            <div>
              <img
                className="max-sm:hidden max-lg:hidden"
                src={ImgContact}
                alt="contactpicture"
                onContextMenu={preventDefaultActions} // Empêche le clic droit
                onDragStart={preventDefaultActions} // Empêche le glisser-déposer
                onMouseDown={preventDefaultActions} // Empêche d'autres actions de souris potentiellement indésirables
              />
            </div>
            <form
              ref={form}
              onSubmit={sendEmail}
              className="max-w-[30%] lg:text-[16px] text-[14px] flex-1 flex flex-col items-center gap-y-2 max-sm:min-w-[80%] max-lg:min-w-[60%]"
            >
              <input
                type="name"
                name="user_name"
                className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white transition-all"
                placeholder="Votre nom"
              />
              <input
                type="name"
                name="user_name"
                className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white transition-all"
                placeholder="Média / Société"
              />
              <input
                type="email"
                name="user_mail"
                className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white transition-all"
                placeholder="Votre e-mail"
                required
              />
              <textarea
                name="user_message"
                className="bg-transparent border-b py-10 outline-none w-full placeholder:text-white transition-all resize-none mb-8"
                placeholder="Votre message"
                required
                minLength={10}
              ></textarea>
              <button
                type="submit"
                value="Send"
                className="border p-3 text-white hover:bg-white hover:text-black"
                disabled={isSubmitting} // Désactiver le bouton si isSubmitting est vrai
              >
                ENVOYER
              </button>
            </form>
          </div>
        </div>
        <ToastContainer />
      </section>

      <Footer />
    </>
  );
};

export default Contact;
