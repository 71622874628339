// ALBUMS
import AB1 from "../assets/albums/AB1.webp";
import AB2 from "../assets/albums/DECCA.webp";
import AB3 from "../assets/albums/AB3.webp";
import AB3A from "../assets/albums/AB3A.jpg";
import AB3B from "../assets/albums/AB3B.jpg";
import AB4 from "../assets/albums/AB4.jpg";
import AB4A from "../assets/albums/AB4A.jpg";
import AB5 from "../assets/albums/1997 Grieg.jpg";
import AB5A from "../assets/albums/AB5A.jpg";
import AB6 from "../assets/albums/RACHMANINOV.jpg";
import AB7 from "../assets/albums/AB5.jpg";
import AB8 from "../assets/albums/AB22.jpeg";
import AB9 from "../assets/albums/VALSES.webp";
import AB10 from "../assets/albums/TCHAI.webp";
import AB11 from "../assets/albums/AB17.webp";
import AB11A from "../assets/albums/AB11A.jpg";
import AB12 from "../assets/albums/RACHMAN.jpg";
import AB13 from "../assets/albums/AB21.jpeg";
import AB14 from "../assets/albums/MARCHE.webp";
import AB15 from "../assets/albums/AB9.webp";
import AB15A from "../assets/albums/AB15A.jpg";
import AB16 from "../assets/albums/AB18.webp";
import AB17 from "../assets/albums/AB12.webp";
import AB17A from "../assets/albums/AB17A.jpg";
import AB18 from "../assets/albums/AB19.webp";
import AB19 from "../assets/albums/AB8.webp";
import AB20 from "../assets/albums/AB15.webp";
import AB22 from "../assets/albums/AB13.jpg";
import AB22A from "../assets/albums/AB22A.jpg";
import AB23 from "../assets/albums/abbrams.jpg";
import AB24 from "../assets/albums/AB24.jpg";
import AB25 from "../assets/albums/carnaval.jpg";
import AB26 from "../assets/albums/KLAVIER.jpg";
import AB26A from "../assets/albums/AB26A.jpg";
import AB26B from "../assets/albums/AB26B.jpg";
import AB26C from "../assets/albums/AB26C.jpg";
import AB27 from "../assets/albums/french.jpg";
import AB21 from "../assets/albums/AB11.webp";
import AB21A from "../assets/albums/AB21A.webp";
import AB28 from "../assets/albums/AB29.webp";
import AB29 from "../assets/albums/1969 eterna.jpg";

// PDF DES ALBUMS
import pdfAB1 from "../assets/pdf/pdfAB1.pdf";
import pdfAB2 from "../assets/pdf/pdfAB2.pdf";
import pdfAB3 from "../assets/pdf/pdfAB3.pdf";
import pdfAB3A from "../assets/pdf/pdfAB3A.pdf";
import pdfAB3B from "../assets/pdf/pdfAB3B.pdf";
import pdfAB4 from "../assets/pdf/pdfAB4.pdf";
import pdfAB4A from "../assets/pdf/pdfAB4A.pdf";
import pdfAB5 from "../assets/pdf/pdfAB5.pdf";
import pdfAB5A from "../assets/pdf/pdfAB5A.pdf";
import pdfAB6 from "../assets/pdf/pdfAB6.pdf";
import pdfAB7 from "../assets/pdf/pdfAB7.pdf";
import pdfAB8 from "../assets/pdf/pdfAB8.pdf";
import pdfAB9 from "../assets/pdf/pdfAB9.pdf";
import pdfAB10 from "../assets/pdf/pdfAB10.pdf";
import pdfAB11 from "../assets/pdf/pdfAB11.pdf";
import pdfAB11A from "../assets/pdf/pdfAB11A.pdf";
import pdfAB13 from "../assets/pdf/pdfAB13.pdf";
import pdfAB14 from "../assets/pdf/pdfAB14.pdf";
import pdfAB15 from "../assets/pdf/pdfAB15.pdf";
import pdfAB16 from "../assets/pdf/pdfAB16.pdf";
import pdfAB17 from "../assets/pdf/pdfAB17.pdf";
import pdfAB17A from "../assets/pdf/pdfAB17A.pdf";
import pdfAB18 from "../assets/pdf/pdfAB18.pdf";
import pdfAB19 from "../assets/pdf/pdfAB19.pdf";
import pdfAB20 from "../assets/pdf/pdfAB20.pdf";
import pdfAB21 from "../assets/pdf/pdfAB21.pdf";
import pdfAB21A from "../assets/pdf/pdfAB21A.pdf";
import pdfAB22 from "../assets/pdf/pdfAB22.pdf";
import pdfAB22A from "../assets/pdf/pdfAB22A.pdf";
import pdfAB23 from "../assets/pdf/pdfAB23.pdf";
import pdfAB24 from "../assets/pdf/pdfAB24.pdf";
import pdfAB25 from "../assets/pdf/pdfAB25.pdf";
import pdfAB26 from "../assets/pdf/pdfAB26.pdf";
import pdfAB27 from "../assets/pdf/pdfAB27.pdf";
import pdfAB28 from "../assets/pdf/pdfAB28.pdf";
import pdfAB26A from "../assets/pdf/pdfAB26A.pdf";
import pdfAB26B from "../assets/pdf/pdfAB26B.pdf";
import pdfAB26C from "../assets/pdf/pdfAB26C.pdf";
import pdfAB29 from "../assets/pdf/pdfAB29.pdf";

export const DiscoData = [
  {
    id: 1,
    title: "2022 - Cécile Ousset, The Complete Warner Recordings",
    subtitle: "",
    ref: "Warner Classics",
    num: "9029643624",
    content: `
      Cécile Ousset a été l’une des figures de proue d’EMI dans les années 1980, notamment grâce à ses splendides collaborations concertos avec Simon Rattle, et ses interprétations inspirées des grands romantiques. Ce recueil rend hommage aux plus grands pianistes du passé avec deux concertos et les Variations Paganini de Rachmaninov, la sonate pour piano de Liszt et les Études Paganini, et diverses pièces de Chopin (sonates, scherzi, ballades). “Like Pollini,” observes Gramophone, “the masterful Ousset commands in this work … [her] Chopin CD will give immense pleasure by reason of her indefatigable musical verve.”`,
    image: AB1,
    pdfLink: pdfAB1,
  },
  {
    id: 2,
    title: "2019 - Cecile Ousset – The Decca France Recordings",
    subtitle: "",
    ref: "Eloquence",
    num: "ELQ4827395",
    content: `Un coffret contenant tous les enregistrements de récital réalisés par Cécile Ousset pour French Decca entre 1971 et 1976, de Satie, Debussy, Saint Saens, et Chabrier, à Rachmaninov et Prokofiev. Le troisième CD nous offre le Carnaval de Schumann et les Variations de Paganini de Brahms dans un enregistrement célèbre, tandis que les quatre derniers comprennent une collection rare de variations de Beethoven, des profondes et ambitieuses Variations de Diabelli, qui toute l'étendue de la technique et de la sensibilité de la pianiste ...
     "The four Beethoven variations discs alone justify purchase of this set. The remaining three discs survey, respectively, French, Russian, and German repertoire. And in each case, Ousset is masterful in surmounting the wide and varied range of technical and interpretive challenges. All of these marvelous performances are captured in beautiful analog sound—rich, colorful, detailed, set in a realistic concert hall acoustic." (Fanfare, March/April 2020).`,
    image: AB2,
    pdfLink: pdfAB2,
  },
  {
    id: 3,
    title: "2013 - 75 Years Ysaÿe & Queen Elisabeth Piano Competition",
    subtitle: "",
    ref: "MUSO",
    num: "MU005",
    content: `Une plongée dans le trésor des archives du Concours Reine Elisabeth et un coffret de luxe de 5 CDs contenant 12 des plus célèbres concertos pour piano du répertoire interprétés par des lauréats exceptionnels, dont 9 premiers lauréats : près de 60 ans séparent les représentations de Leon Fleisher (1952) dans le Premier Concerto de Brahms et de Denis Kozhukhin (2010) dans son Second. Un ensemble qui permet de redécouvrir des personnalités qui ont eu un impact sensationnel sur le public et les jurys : Cécile Ousset en 1956 et les talents émergents de Frank Braley en 1991, le pianiste allemand Wolfgang Manz en 1983 et, plus récemment, Anna Vinnitskaya.`,
    image: AB3,
    pdfLink: pdfAB3,
  },
  {
    id: 4,
    title:
      "2004 - Cécile Ousset,Sir Neville Mariner, Allan Marks, Hans-Dieter Baum - George Gershwin, Rhapsody in Blue, Concerto in F, An American in Paris, Cuban Overture",
    subtitle: "",
    ref: "Capriccio",
    num: "C51156",
    content: `"The rush to bring Gershwin fare to market before his centenary year is out has inevitably thrown up dross as well as gold. In addition to capturing the attention of serious musicians, Gershwin remains attractive to purveyors of schmaltz and tinkly hotel lounge Muzak, big-name orchestras sleeping through slushy arrangements of show songs. Thankfully, this stunning set is pure gold. The first CD has the orchestral masterpieces, never sounding fresher or more full of vitality. Rhapsody in Blue, with Neville Marriner and the Stuttgart RSO electrifying and Cécile Ousset’s playing acrobatic and infectious, is riveting from start to finish. In An American in Paris, Marriner repeats the trick of invoking a charming, spontaneous atmosphere with no sacrifice to accuracy or detail." (BBC Music Magazine)`,
    image: AB3A,
    pdfLink: pdfAB3A,
  },
  {
    id: 5,
    title:
      "2000 - Various – The Best Proms Album In The World... Ever! BBC Music Production",
    subtitle: "",
    ref: "Virgin",
    num: "8 49731 2",
    content: `Cécile Ousset et Sir Simon Rattle, City Of Birmingham Symphony Orchestra, figure en bonne place dans cette vaste compilation produite en deux CD qui entend restituer tout le piquant des fameuses BBC Proms,  festival annuel de musique classique tenu au Royaume-Uni.  Une sélection de pièces tant populaires qu'emblématiques, "Pomp and Circumstance March No. 1", by Elgar, "Nimrod from Enigma Variations", by Elgar, "1812 Overture", by Tchaikovsky, "Ode to Joy from Beethoven's Symphony No. 9", "The Blue Danube by Johann Strauss II".`,
    image: AB3B,
    pdfLink: pdfAB3B,
  },
  {
    id: 6,
    title:
      "1999 - Cecile Ousset, The Radio 3 Lunchtime Concert - Ravel, Saint-Saens",
    subtitle: "",
    ref: "BBC",
    num: "103",
    content:
      "BBC Music Worldwide sort en mars 1999 un CD d’un récital Chopin/Ravel enregistré en direct au Wigmore Hall, année du retour de Cécile Ousset à la Philharmonique de New pour jouer Saint-Saens No. 2, sous la direction de Fabio Luisi.",
    image: AB4,
    pdfLink: pdfAB4,
  },
  {
    id: 7,
    title:
      "1999 - Cécile Ousset, Debussy – Pour Le Piano; L'Isle Joyeuse; Images, Books I & II; Arabesques Nos. 1 & 2; Suite Bergamasque",
    subtitle: "",
    ref: "EMI Classics",
    num: "7243 5 73536 2 5",
    content: ` "These are higly sophisticated readings, backed up by a superbly regulated technique" (Gramaphone) - L'album de 1999 de Cécile Ousset consacré à Debussy est un témoignage de son talent exceptionnel et de sa compréhension profonde de la musique impressionniste. Ses interprétations de "Pour le Piano," "L'Isle Joyeuse," et "Images" sont appréciées pour leur virtuosité, leur sensibilité, et leur profondeur expressive. Pour les amateurs de Debussy et les admirateurs de Cécile Ousset, cet enregistrement est un complément précieux à toute collection de musique classique. Les passages exigeants de la "Toccata" de "Pour le Piano" et les nuances délicates de "L'Isle Joyeuse" sont rendus avec une grande précision. De même,  sa sensibilité musicale permet de faire ressortir les couleurs et les atmosphères des "Images", offrant une interprétation qui transporte l'auditeur dans les mondes évocateurs de Debussy. Enfin, sachant parfaitement utiliser des dynamiques variées et un rubato délicat pour donner vie aux pièces, son interprétation de "Reflets dans l'eau" et "Poissons d'or" démontre particulièrement cette capacité à peindre des tableaux sonores.`,
    image: AB4A,
    pdfLink: pdfAB4A,
  },
  {
    id: 8,
    title:
      "1997 - Cécile Ousset & Neville Marriner & Kurt Masur -  Grieg & Schumann, piano concertos",
    subtitle: "",
    ref: "EMI Classics",
    num: "7243 5 73428 2 7",
    content: `Une réédition de deux oeuvres majeures partagées avec deux immenses chefs d'orchestre, et en bonne place dans leurs rétrospectives réciproques : Neville Marriner (1985), l'un des chefs d'orchestre les plus enregistrés et les plus vendus de tous les temps ("The Complete Warner Classics Recordings" réalisés entre 1970 et 2000 pour EMI, Erato, Virgin et Teldec, et comportant 80 CD), et Kurt Masur (1991), "le rayonnement d'un parcours construit du Gewandhaus de Leipzig à l'Orchestre national de France, en passant par le New York Philharmonic et celui de Londres". Deux enregistrements qui rappellent que la pianiste Cécile Ousset "leaves her own distinctive stamp on everything she plays" (Gramophone).`,
    image: AB5,
    pdfLink: pdfAB5,
  },
  {
    id: 9,
    title: `1996 - Cécile Ousset & Günther Herbig, Philharmonia Orchestra - Rachmaninov (Piano concerto No 3) & Mussorgsky, (Pictures From An Exhibition")  `,
    subtitle: "",
    ref: "EMI",
    num: "CDC 7 49941 2",
    content: `La pianiste Cécile Ousset, le chef d'orchestre Günther Herbig, et l'Orchestre Philharmonia se complètent parfaitement autour de deux œuvres majeures du répertoire classique, le Concerto pour piano n° 3 de Rachmaninov et les Tableaux d'une exposition de Mussorgsky. Composé par Sergei Rachmaninov en 1909, le concerto est l'un des plus célèbres et des plus techniquement exigeants du répertoire pianistique. "Allegro ma non tanto",  son premier mouvement est connu pour son thème principal. L'Orchestre Philharmonia, conduit par Günther Herbig, est réputé pour sa richesse sonore et sa précision, ce qui est idéal pour une œuvre aussi colorée que les Tableaux d'une exposition, composée en 1874 par Modest Mussorgsky, et orchestrée par la suite par Maurice Ravel.`,
    image: AB5A,
    pdfLink: pdfAB5A,
  },
  {
    id: 10,
    title:
      "1992 - Cécile Ousset & Simon Rattle, City of Birmingham, John Ogdon – Rachmaninov, Piano Concerto No 2, Paganini Rhapsody, Preludes",
    subtitle: "",
    ref: "HMV Classics",
    num: "5721442",
    content: `Compilation HMV Classsic, et sessions EMI enregistrées en 1984 pour deux oeuvres marquantes de la collaboration de Cécile Ousset et du City Of Birmingham Symphony Orchestra sous la direction de Simon Rattle :  le "Piano Concerto No 2 In C Minor Op 18" et la "Rhapsody On A Theme Of Paganini Op 43", qui compte parmi celles interprétées par des pianistes tels que Ashkenazy ou Rubinstein. Deux oeuvres que complètent John Ogdon, pianiste et compositeur, ici interprétant quatre Préludes de Rachmaninov.`,
    image: AB6,
    pdfLink: pdfAB6,
  },

  {
    id: 11,
    title:
      "1992 - Cécile Ousset & Sir Neville Marriner, Radio-Sinfonieorchester Stuttgart - George Gershwin, Rhapsody In Blue - Concerto In F - An American In Paris",
    subtitle: "",
    ref: "Capriccio",
    num: "C51156",
    content: `De la musique baroque et classique à ses célèbres enregistrements de l’Adagio pour cordes de Samuel Barber et de la Rhapsody in Blue de George Gershwin, - grande époque de ses "Stuttgart Recordings" - le répertoire de Neville Barber était étonnamment large : Cécile Ousset l'a suivi tant dans "Rhapsody in Blue" que dans le "Piano Concerto in F major", des oeuvres qui seront reprises dans nombre de compilations devenues classiques.
    "The rush to bring Gershwin fare to market before his centenary year is out has inevitably thrown up dross as well as gold. In addition to capturing the attention of serious musicians, Gershwin remains attractive to purveyors of schmaltz and tinkly hotel lounge Muzak, big-name orchestras sleeping through slushy arrangements of show songs. Thankfully, this stunning set is pure gold. The first CD has the orchestral masterpieces, never sounding fresher or more full of vitality. Rhapsody in Blue, with Neville Marriner and the Stuttgart RSO electrifying and Cécile Ousset’s playing acrobatic and infectious, is riveting from start to finish. In An American in Paris, Marriner repeats the trick of invoking a charming, spontaneous atmosphere with no sacrifice to accuracy or detail." (BBC Music Magazine)`,
    image: AB7,
    pdfLink: pdfAB7,
  },
  {
    id: 12,
    title: `1991 - Cécile Ousset & Simon Rattle, City Of Birmingham Symphony Orchestra - Ravel, "The Two Piano Concertos" & "Le Tombeau de Couperin" (Piano Solo)`,
    subtitle: "",
    ref: "EMI Digital",
    num: "CDC 7 54158 2",
    content: `Le duo Ousset/Rattle offre un concerto pour piano en sol de Ravel de référence, "Ravel: Piano Concerto in G major" est repris dans nombre de compilations ("Simon Rattle Conducts Ravel", Warner Classics, 2022). Les œuvres de Ravel, comme celles de Debussy, ont jalonné la vie de Cécile Ousset ...
    "The first impression of this Ravel G major Concerto is of vivacity combined with delicacy, and that initial view is sustained throughout what follows. Cecile Ousset and Simon Rattle manage the difficult transition to the E major second subject in the first movement adroitly, and in what follows they rightly refuse to allow the busyness of the writing to become breathless. In all, this is stylish playing. If one has any criticism, it must simply be that it sounds a little safe and less than fully spontaneous. But make no mistake, it is secure and enjoyable. The acid test of musicianship in this work, some would say, is the soloist's eloquent opening melody in the slow movement, and Ousset passes it without, however, eclipsing the more tender approaches of other pianists, notably Pascal Roge (Decca) and Jean-Philippe Collard (EMI—a Gramophone Award-winner); for evidence of real affection here, one awaits the entry of the orchestra and its coolly tender woodwind solos. The finale has all the verve it needs, but the notoriously difficult bassoon solo at 2'03'' could be crisper.
    The darkly smouldering Left-hand Concerto is placed as the last of the three works here, with Le tombeau de Couperin second. Rattle ushers in the first piano cadenza with great dramatic strength, and Ousset matches him in attack and command in a fine performance that brings out the power of the language without exaggeration. This is a distinguished account of the work, with the right element of tendresse in the second subject and alarming tension in the jazzy middle section. It is excellently recorded, with realistic piano and orchestral sound and a well judged balance. Ousset's fine technique serves her well in Le tombeau de Couperin, ..." (Gramophone, 1991)`,
    image: AB8,
    pdfLink: pdfAB8,
  },

  {
    id: 13,
    title:
      "1990 - Cécile Ousset & Kurt Masur, The London Philharmonic - Tchaikovsky & Schumann, Piano Concertos",
    subtitle: "",
    ref: "Warner Classics",
    num: "9029620469",
    content: `Pour son dernier enregistrement sous le label EMI, Cécile Ousset retrouve Kurt Masur pour interpréter avec le London Philharmonic Orchestra, le Premier de Tchaïkovski et le Concerto de Schumann, "un couplage d'un fort symbolisme. Une rare tendresse, peu entendue dans ses précédentes gravures, conduit le Concerto de Schumann, l'une de ses œuvres préférées avec
      les concertos de Brahms. Là, la virtuosité est d'un trait plus subtil, soufflant une telle intimité dans les phrases poignantes du Concerto que l'interprétation, accordée à un aspect attachant et fragile, surprend autant qu'elle émeut."`,
    image: AB10,
    pdfLink: pdfAB10,
  },
  {
    id: 14,
    title:
      "1989 - Cécile Ousset & Günther Herbig, The Philharmonia - Rachmaninov, Piano Concerto No.3 & Piano Sonata No. 2  ",
    subtitle: "",
    ref: "Warner Classics",
    num: "5419714773",
    content: `Aux côtés de Günther Herbig et du Philharmonia Orchestra, Cécile Ousset grave avec EMI le troisième concerto de Rachmaninov qu'elle qualifie de "défi ultime"  pour une femme en raison des grandes octaves qui parsèment l'oeuvre, laquelle est publiée avec la Sonate du compositeur  sur le même disque, une version originale.`,
    image: AB11,
    pdfLink: pdfAB11,
  },
  {
    id: 15,
    title:
      "1989 - Cecile Ousset, Debussy, Images Book I & II, Arabesque No. 1, 2,  Suite Bergamasque",
    subtitle: "",
    ref: "EMI Digital",
    num: "CDS 7 47608 8",
    content: `Deuxième des deux volumes enregistrés par EMI et consacré aux oeuvres de Debussy interprétées par Cécile Ousset, - les "Images", les deux "Arabesques", et la "Suite Bergamasque". "One always feels that she has something in reserve, both regarding technique and powers of expression. Coming after the fluidity of ''Reflets dans l'eau'', the ''Hommage a Rameau'' has a quaint, old-world stateliness, with beautifully graded dynamics, that demonstrates the richness of her response. The sunny exuberance of ''Mouvement'' is ideally suited to Ousset's temperament and yet she can turn to Debussy's reflective seriousness in ''Cloches a travers les feuilles'' with equal success and conviction. One might quibble with the virtuoso approach to ''Poissons d'or''—there is also a certain shortage of humour there—but Ousset's experience with this music is unquestionable and therefore is compelling ..." (Gramophone, 1989)`,
    image: AB11A,
    pdfLink: pdfAB11A,
  },
  {
    id: 16,
    title:
      "1988 - Cécile Ousset & Simon Rattle, City Of Birmingham Symphony Orchestra - Ravel, Valses Nobles Et Sentimentales, Jeux d'eau, Miroirs, Concertos",
    subtitle: "",
    ref: "Warner Classics",
    num: "9029615157",
    content: `Un album EMI qui offre une parfaite vue d'ensemble de la diversité et de la subtilité des compositions de Ravel, cette si suggestive impression de fluidité et de scintillement qui caractérise le compositeur et s'exprime parfaitement tant au travers des oeuvres pour piano seul (Valses Nobles et Sentimentales, Jeux d'eau, Miroirs) que dans les œuvres orchestrales (Concerto pour piano en sol majeur, Concerto pour la main gauche) dans lesquelles Cécile Ousset et le CBSO, sous la direction de Simon Rattle, se complètent parfaitement.`,
    image: AB9,
    pdfLink: pdfAB9,
  },
  {
    id: 17,
    title:
      "1987 / 1989 - Cécile Ousset, Mikhail Pletnev, Günther Herbig, Libor Pešek, Philharmonia Orchestra - Rachmaninov, Piano Concertos Nos. 1 & 3",
    ref: "Warner Classics",
    num: "2282792",
    content: `Une réédition Warner d'enregistrements des années 1987-1989 que se partagent autour de Rachmaninov Cécile Ousset (piano) et Günther Herbig (Philharmonia Orchestra), d'une part (Concerto n°3), Mikhail Pletnev (piano) et Libor Pešek (Philharmonia Orchestra), d'autre part (Concerto n°1).`,
    image: AB12,
  },

  {
    id: 18,
    title:
      "1987 - Cécile Ousset – Chopin, Piano Sonatas 3 in B minor, Four Ballades",
    subtitle: "",
    ref: "EMI Digital",
    num: "CDC 7 49169 2",
    content: `Dans ce deuxième volume consacré par EMI à Chopin, Cécile Ousset nous livre "lyrisme poignant et sentiment héroïque" débordant dans la troisième sonate, avec "ses tempos judicieux aussi admirables que le naturel du jeu qui s'avère à tous égards envoûtant, sans jamais frôler l'extravagance". Un critique de Gramophone observera : « Comme Pollini, la magistrale Ousset est aux commandes de cette oeuvre. Son disque sur Chopin accordera le plus grand plaisir de par son brio musical infatigable." La "Ballade No. 3 in A flat major, Op. 47", enregistrée en 1987 figure dans nombre de compilations ("50 Best Chopin", Warner Classics, 2010, "Essential Chopin", Warner Classics, 2010).`,
    image: AB13,
    pdfLink: pdfAB13,
  },
  {
    id: 19,
    title: `1987 - Cécile Ousset - Chopin, Sonata No. 2 "Marche Funèbre", 4 Scherzi`,
    subtitle: "",
    ref: "Warner Classics",
    num: "5419714772",
    content: `Cécile Ousset semble donner une signification toute personnelle aux deux sonates de Chopin, réparties sur deux albums (EMI) avec l'intégrale des "Ballades" et des "Scherzos" : "La deuxième sonate, d'une telle imprévisibilité explosive - un rassemblement de quatre des enfants les plus turbulents de Chopin, selon Schumann - trouve une voix idéale dans le jeu séduisant et brillant de la pianiste, laquelle apporte un élan exaltant aux mouvements extérieurs et une gravité spectaculaire à la célèbre "Marche funèbre" ainsi qu'aux scherzos, d'une audace surprenante.`,
    image: AB14,
    pdfLink: pdfAB14,
  },
  {
    id: 20,
    title: `1986 - Cécile Ousset, "Debussy: Préludes, Books I & II"`,
    subtitle: "",
    ref: "Warner Classics",
    num: "9029643624",
    content: `Les oeuvres de Debussy interprétées par Cécile Ousset - l'intégralité des "Préludes" et des "Images", les deux "Arabesques", "L'Isle joyeuse", "Pour le piano" et la "Suite Bergamasque" - s'étalent sur deux enregistrements EMI parus en 1986 et en 1989. "L'immense affinité que la pianiste ressentait pour Debussy était considérable" et  elle livre ici "une lecture saisissante qui interpelle autant qu”elle subjugue, un véritable tour de force qui ne se dérobe pas devant des contrastes puissants, laissant intact son toucher d'acier. Chaque timbre et sonorité de l'instrument est exploité, créant des images vives qui rayonnent de la partition", évoquant les propos de Debussy lui-même receuilis lors d'un entretien célèbre avec Henry Malherbe, "Le
    bruit de la mer, la courbe d'un horizon, le vent dans les feuilles, le cri d'un oiseau déposent en nous de multiples impressions. Et tout d'un coup, sans qu'on y attende le moins du monde, l'un de ces souvenirs se répand hors de nous et s'exprime en langage musical".`,
    image: AB15,
    pdfLink: pdfAB15,
  },
  {
    id: 21,
    title: `1986 - Maria Tipo, Tatiana Nikolajewa, Cécile Ousset, Dinorah Varsi, Nataša Veljković, Konstanze Eickhorst – "Pianistinnen Portraits, Die Schönsten Klavierstücke" - Live Aus Berlin
    Deutsche`,
    subtitle: "",
    ref: "harmonia mundi Digital",
    num: "1C 056Y3000001",
    content: `Cécile Ousset joue Frédéric Chopin (Nocturne Des-Dur Op 27 Nr 2) et Camille Saint-Saëns (Etude Toccata Nach dem Konzert Für Klavier Und Orgel Nr.5) dans un ensemble, fastueux et trop court, enregistré lors d'une session Berlin Radio Station SFB. Sont réunies ici six talentueuses interprètes, dont la napolitaine Maria Tipo (ses sonates de Scarlatti sont incomprables), la russe Tatiana Nikolayeva (un Schumann tout en émotions), l'urugayenne Dinorah Varsi (Debussy), la serbe Nataša Veljković (Mozart), et l'allemande Konstanze Eickhorst (Prokofiev). Un hommage réussi aux pianistes féminines et à la beauté intemporelle du répertoire pour piano.`,
    image: AB15A,
    pdfLink: null,
  },
  {
    id: 22,
    title:
      "1985 - Cécile Ousset & Sir Neville Marriner, London Symphony Orchestra - Grieg, Piano Concerto - Mendelssohn, Piano Concerto No. 1",
    subtitle:
      "",
    ref: "Warner Classics",
    num: "5419714767",
    content: `Cécile Ousset interprète Grieg et Mendelssohn, avec l’Orchestre symphonique de Londres sous la conduite de Neville Marriner : deux concertos EMI entrés dans la légende de la discographie de « l’homme aux six cents disques »  (« Sir Neville Marriner. The Complete Warner Classics Recordings », Warner, 80 CD), deux concertos que balaie "le souffle d'un romantisme fiévreux" (Diapason, 2024).`,
    image: AB16,
    pdfLink: pdfAB16,
  },
  {
    id: 23,
    title: `1985 - Cécile Ousset "Plays Liszt" - Sonata In B Minor (Sonate H-Moll), Grandes Études De Paganini`,
    ref: "Warner Classics",
    num: "9029643624",
    content: `Une gravure EMI consacrée à Franz Liszt où se côtoient les six Grandes Études de Paganiní, oeuvre représentative du profil virtuose du compositeur et de son admiration pour Paganini, dédiée à Clara Schumann, et la Sonate en si mineur, testament pianistique du romantisme qui fut dédié à Robert Schumann. Les œuvres de Liszt n'avaient pas le plus souvent leur place en tête d'un récital, regrettait Cécile Ousset. Les proportions impressionnantes de la Sonate "exigent en effet non seulement une virtuosité époustouflante mais aussi le désir d'aller au-delà de la narration pour creuser dans les profondeurs spirituelles et métaphysiques de l'oeuvre. D'une éloquence qui fait merveille, la pianiste sait nous entraîner vers les sommets vertigineux de l'oeuvre.`,
    image: AB17,
    pdfLink: pdfAB17,
  },
  {
    id: 24,
    title:
      "1984 - 1985 - Cécile Ousset, Neville Marriner, London Symphony Orchestra & Simon Rattle, City Of Birmingham Symphony Orchestra – Grieg & Rachmaninov, Piano Concertos",
    subtitle: "",
    ref: "EMI Digital",
    num: "CDD 7 63903 2",
    content: `Une compilation EMI qui réunit autour de Rachmaninov (Piano Concerto No. 2 ) et de Grieg (Piano Concerto In A Minor), Cécile Ousset au piano, Simon Rattle & the City Of Birmingham Symphony Orchestra, d'une part, et Sir Neville Marriner, & the London Symphony Orchestra, d'autre part. Créé comme soliste en 1901,le Concerto pour piano no 2 de Rachmaninov est l’une des œuvres les plus puissantes du répertoire pour piano. Et si le seul concerto pour piano composée par Grieg en 1868 est loin d’être techniquement difficile, son lyrisme et sa conception, proche de Schumann, enthousiasma Liszt.`,
    image: AB17A,
    pdfLink: pdfAB17A,
  },
  {
    id: 25,
    title:
      "1984 - Cécile Ousset & Simon Rattle & City Of Birmingham Symphony Orchestra - Rachmaninov, Concerto No.2, & Rhapsodie Sur Un Thème De Paganini",
    subtitle:
      "",
    ref: "Warner Classics",
    num: "9029613091",
    content: `Cécile Ousset retrouve dans cet immense Concerto pour piano no 2 de Rachmaninov et dans sa Rhapsodie sur un thème de Paganini (EMI), l’un de ses chefs préférés, Simon Rattle. On retrouvera dans nombre de compilations des extraits de ces deux classiques. 
    "Though the piano is nearly always at the centre of attention in Rachmaninov's Concerto No. 2, it is a lyrical work rather than a display vehicle. As such, it benefits from the careful and sensitive performance—and recording—it receives here. This may be unexpected from the exuberant Cecile Ousset, but there is so much sheer beauty here, such as her quiet-voiced statements of the finale's second subject, or the poco meno mosso just after fig. 15 in the first movement. Rattle pays close attention to every orchestral detail, as is evident when the melody is in the violas or cellos, or quietly in the woodwind—hear the Adagio's lovely clarinet solo. for a remarkable percentage of the time the soloist's role is that of accompanist, as in both statements of the work's opening melody, and in such places the balance between piano and orchestra is particularly happy.
    However, I suspect that both pianist and conductor are temperamentally better suited to the Rhapsody, and this indeed receives an immensely enjoyable performance, though, again, one that is also thoughtful. both orchestration and piano writing are considerably more varied than in the concerto, and these are responded to with zest and a whole range of precisely focused nuances. At the same time the two dozen variations—each exactly characterized—are united in a single energetic sweep. And here again the recording is well attuned to the particular quality of the performance. All too often these two works make a routine coupling, but here it is altogether different." (Gramophone, 1984)`,
    image: AB18,
    pdfLink: pdfAB18,
  },

  {
    id: 26,
    title: `1983 - Cécile Ousset, "French Piano Music"`,
    subtitle: "",
    ref: "Warner Classics",
    num: "5419713873",
    content: `Les oeuvres du répertoire français demeurent un élément primordial du répertoire EMI de Cécile Ousset qui consacre en 1983 un récital entièrement consacré à la musique française, associant les Estampes de Debussy à une poignée d'œuvres de Fauré, Ravel, Chabrier, Satie et Saint-Saëns. Ici "l'intégrité de la phrase musicale n'est jamais trahie par une démonstration gratuite comme atteste le deuxième "Impromptu" de Fauré dont les envolées précipitées à la tarentelle font la part belle à un brio naturel qui contraste merveilleusement avec la fine musicalité de la pianiste...." Un "Impromptu" qui figure  dans nombre de compilations ("100 Best Piano Classics", 2007, Warner Classics).`,
    image: AB19,
    pdfLink: pdfAB19,
  },
  {
    id: 27,
    title:
      "1982 - Cécile Ousset & Rudolf Barshai, Bournemouth Symphony Orchestra - Prokofiev & Poulenc, Piano Concertos",
    subtitle:
      "",
    num: "5419714106",
    ref: "Warner Classics",
    content: `Parmi les quatre premiers albums que Cécile Ousset inaugure sous le label EMI, le Concerto pour piano no 3 op. 26 de Prokofiev et le Concerto pour piano de Poulenc , interprétés sous la conduite de Rudolf Barshai s'avèrent des enregistrements jugés encore aujourd'hui comme de référence ...
      "Ousset's performance is noted for its integration with the orchestra rather than standing out as a solo virtuoso display, which suits the nature of Prokofiev’s composition. Her interpretation of Poulenc’s concerto is described as vigorous and energetic, offering a slight edge over other performers like Cristina Ortiz​" (Gramophone)​.`,
    image: AB20,
    pdfLink: pdfAB20,
  },
  {
    id: 28,
    title:
      "1982 - Cecile Ousset - Mussorgsky, Pictures at an Exhibition - Ravel, Gaspard de la Nuit",
    subtitle:
      "",
    num: "5419713600",
    ref: "Warner Classics",
    content: `Associant les "Tableaux d'une exposition" de Moussorgski et "Gaspard de la nuit" de Ravel, c'est le premier récital enregistré par EMI Classics : autant la version orchestrale des "Tableaux" signée Ravel est celle la plus connue de l'œuvre, "l'original pour piano demeure en soi un véritable exercice d'imagination en ce qui concerne textures et couleurs, opposant la qualité orchestrale du piano à sa nature plus intime". "Gaspard de la nuit", "écrite laborieusement en 1908 représente quant à elle l'apogée du langage musical de son auteur, pleinement épanoui et entièrement distinct, s'éloignant des précédentes tentatives de s'accommoder aux conventions".  L'enregistrement fut chaleureusement accueilli.`,
    image: AB21,
    pdfLink: pdfAB21,
  },
  {
    id: 29,
    title:
      "1982 - Cécile Ousset & Simon Rattle, City Of Birmingham Symphony Orchestra – Saint-Saëns, Piano Concerto No. 2, Op. 22 - Liszt, Piano Concerto No. 1",
    subtitle: "",
    num: "9029643624",
    ref: "Warner Classics",
    content: `Aux côtés de Cécile Ousset, un jeune Simon Rattle aux commandes de l'orchestre symphonique de Birmingham, le début d'une collaboration fructueuse qui donne naissance à plusieurs enregistrements EMI.
    "Edward Greenfield warmly praised these performances five years ago, and I welcome their reissue on Cd with no less enthusiasm. Ousset would not be Ousset without overbrimming strength, drive and sparkle. But I was still more impressed by her ruminative poetry and acutely sensitive interplay with the orchestra. She's always had a special affinity with Rattle and the CBSO, and here they reveal the true worth of music that can, in some hands, sound merely superficial. My colleague did in fact find the piano too forwardly placed for his own liking, but this didn't worry me at all on the CD. I thought sumptuous climaxes were just as happily shared and balanced as more intimate exchanges with solo instruments—except, perhaps, for one slightly backward solo violin in Liszt's opening movement. The actual quality of sound I enjoyed both for its warmth and roundness in grandioso bravura no less than for its glinting clarity in both concertos' scherzando florics." (Joan Chissell, Gramophone, 1987)
    `,
    image: AB21A,
    pdfLink: pdfAB21A,
  },
  {
    id: 30,
    title: `1981 - Cécile Ousset, "Chopin Recital"`,
    subtitle:
      "",
    num: "CAM 1",
    ref: "Cambridge Records",
    content:
      "Alors que la Sonate n°2 op.35 pour piano écrite 1839 par Chopin tourne principalement autour de la Marche funèbre, et son quatrième et ultime mouvement, impétueux et fougueux, qui balaie tout le clavier, la Ballade No. 4, achevée en 1842, est  l’un des chefs-d’œuvre de la musique pour piano du XIXe siècle : une ballade connue comme la plus difficile, tant techniquement que musicalement, et qui ne pouvait que fasciner la pianiste Cécile Ousset ...",
    image: AB22,
    pdfLink: pdfAB22,
  },
  {
    id: 31,
    title: `1977 - Cécile Ousset – Beethoven, Intégrale Des Variations Pour Clavier - Volume II`,
    subtitle: "",
    num: "7474/75",
    ref: "Decca",
    content: `Deuxième volume de l'intégrale des variations de Beethoven, qui couvre celles d'Ernst Christoph Dressler WoO 63 jusqu'aux Variations Diabelli op. 120. Les deux volumes seront repris dans "The Decca France Recordings" (2019) à propos duquel "The four Beethoven variations discs alone justify purchase of this set", écrira le magazine américain Fanfare ...`,
    image: AB22A,
    pdfLink: pdfAB22A,
  },
  {
    id: 32,
    title:
      "1976 - Cécile Ousset & Kurt Masur, Gewandhausorchester Leipzig – Brahms, Klavierkonzert Nr. 2",
    subtitle: "",
    num: "14.025 A",
    ref: "Decca",
    content: `Le "Piano Concerto No. 2 in B flat major, Op. 83" de Brahms, peut-être le plus grand des concertos parce qu'il unit discipline classique et liberté romantique à un degré sans précédent, compte, dans cette interprétation, plus de 8 rediffusions successives de 1975 (Decca 14.025A, la première, LP Stereo) à 2007 (Berlin Classics, CD). Si son "Allegretto grazioso, figure dans nombre de compilations ("Kurt Masur, The Maestro", Berlin Classics, 2011; "The Loveliest Piano Pieces", Berlin Classics; "Piano Dreams, The Most Beautiful Piano Works", Berlin Classics), le concerto est une des interprétations incontournables qui figurent parmi les 13 CD qui composent les "Legendary Recordings" de Kurt Masur publiés en 2002 par Berlin Classics.`,
    image: AB23,
    pdfLink: pdfAB23,
  },
  {
    id: 33,
    title:
      "1976 - Cécile Ousset - Beethoven, Intégrale Des Variations Pour Piano, volume I",
    subtitle: "",
    num: "7439/41",
    ref: "Decca",
    content: `"L'intégrale des variations de Beethovenpublié par Decca en 2 volumes, est rarement osé, même chez les Beethovéniens les plus fervents, les Diabelli leur suffisent, à la rigueur les Eroica ; seul Alfred Brendel dans ses jeunes années Vox avait été tenté, Cécile Ousset a osé et elle emporte l'adhésion critique par ses dynamiques et ses attaques avec une palette de nuances étonnante."`,
    image: AB24,
    pdfLink: pdfAB24,
  },
  {
    id: 34,
    title:
      "1974 - Cécile Ousset - Robert Schumann, Carnaval Op. 9 - Brahms, Variations On A Theme By Paganini Op. 35",
    subtitle: "",
    num: "7197",
    ref: "Decca",
    content: `Un enregistrement qui associe deux jalons de la littérature pianistique romantique allemande, Brahms et Schumann, "Ousset’s performance is vital and assured, grandly shaped where grandeur is in place, capable of brilliance and a certain cool aggression, tonally apt. In eschewing unnecessary colouring she never starves the music of tone" (Gramophone, August 1975).`,
    image: AB25,
    pdfLink: pdfAB25,
  },
  {
    id: 35,
    title: `1974 - Cécile Ousset - Mozart, Klaviersonate F-dur Kv 332, Klaviersonate B-dur Kv 333, 12 Variation en C-dur Kv 265 "Ah, Vous Dirai-je, Maman"`,
    subtitle: "",
    num: "8 26 476",
    ref: "Eterna",
    content: `Cécile Ousset aborde  trois œuvres célèbres de Wolfgang Amadeus Mozart, trois oeuvres, les sonates pour piano en fa majeur KV 332, - l'une des sonates les plus populaires du compositeur (1783) -, et en si bémol majeur KV 333, - une sonate connue pour sa richesse mélodique et son équilibre formel -, ainsi que les 12 Variations en do majeur KV 265 sur le thème "Ah, vous dirai-je, maman" qui montrent toute la capacité du compositeur à transformer un thème simple en une série de variations complexes et variées.`,
    image: AB26,
    pdfLink: pdfAB26,
  },
  {
    id: 36,
    title: `1974 - Cécile Ousset - Mozart – Klaviersonate A-dur KV 331, Klaviersonate C-dur KV 330, Klaviersonate A-moll KV 310
    Eterna"`,
    subtitle: "",
    num: "8 26 475",
    ref: "Eterna",
    content: `Si la sonate K 310 en la mineur est la plus connue des sonates dites parisiennes (1777-1778), la sonate en La majeur, KV 331, composée dans les années 1780, est la plus célèbre sonate pour piano de Mozart, notamment pour pour son troisième et dernier mouvement, dit « Marche turque » (Rondo alla Turca), mais tout autant par sa structure totalement singulière. `,
    image: AB26A,
    pdfLink: pdfAB26A,
  },
  {
    id: 37,
    title: `1974 - Cécile Ousset & Herbert Kegel, Rundfunk-Sinfonieorchester Leipzig -  Maurice Ravel, Konzert Für Klavier Und Orchester G-Dur`,
    subtitle: "",
    num: "4033008904120",
    ref: "Weitblick",
    content: `Trois oeuvres de Maurice Ravel interprété par l'ensemble de la Rundfunk-Sinfonieorchester Leipzig, sous la conduite de Herbert Kegel : le"Boléro" (enregistré en 1985, Bethanienkirche, Leipzig), "Daphnis et Chloé Suite Nr.2" (enregsitré en 1965, Kongreßhalle Leipzig Konzertmitschnitt, Monaural) et le "Konzert für Klavier und Orchester G-dur", avec Cécile Ousset au piano (enregistré en 1974 Bethanienkirche, Leipzig).`,
    image: AB26B,
    pdfLink: pdfAB26B,
  },
  {
    id: 38,
    title: `1974 - Cécile Ousset - Rachmaninov, 5 pièces pour piano op 3 - Prokofiev, pièces pour piano op. 12`,
    subtitle: "",
    num: "7474/75",
    ref: "Decca",
    content: `Un disque qui propose un aperçu du génie créatif de Rachmaninov et de Prokofiev dans la composition pour piano seul. Sergei Rachmaninov entame sa carrière de compositeur avec cinq courtes pièces pour piano, qu’il présente pour la première fois en décembre 1892, et parmi celles-ci, la deuxième pièce, Prélude en Do dièse mineur, sera la plus célèbre et la plus jouée dans le monde entier. Tenues pour (relativement) faciles en terme d'exécution, elles sont toutes représentatives du style "Rachmaninov". Les dix pièces pour piano opus 12 de Serge Prokofiev, composées entre 1906 et 1913, révèlent quant à elles le style emblématique du compositeur, caractérisé par ses harmonies audacieuses et ses grandes complexités rythmiques.  `,
    image: AB26C,
    pdfLink: pdfAB26C,
  },
  {
    id: 39,
    title: `1971 - Cécile Ousset, "French Piano Music" - Debussy, Satie, Saint-Saëns, Chabrier`,
    subtitle:
      "",
    num: "7429",
    ref: "Decca",
    content: `Première vague d'enregistrements dits "français Decca" qui dateront tous de 1971-1976 et comprennent une grande partie du répertoire solo qu'elle n'a plus enregistré que lors cette session.`,
    image: AB27,
    pdfLink: pdfAB27,
  },
  {
    id: 40,
    title: `1971 - Cécile Ousset - Ravel, "Gaspard de la nuit", "Jeux d'eau", "Miroirs"`,
    subtitle: "",
    num: "8 26 199",
    ref: "Eterna",
    content: `Cinq rediffusions réalisées depuis 1971 (version LP Eterna) jusqu'en 2002 (Berlin Classics, CD), et une reprise Berlin Classics de 1994 (0013452BC), "Claude Debussy Maurice Ravel - Klavierwerke (Works For Piano)" montre à quel point Cécile Ousset est de ces interprètes qui ont su parfaitement nous faire partager quelques-unes des œuvres les plus célèbres de Maurice Ravel pour piano. Son interprétation de "Gaspard de la Nuit" sera particulièrement saluée pour sa virtuosité technique et son expressivité.  De même, lorsque la pianiste aborde "Miroirs", suite pour piano composée de cinq pièces, chacune avec ses propres défis techniques et expressifs : elle sait parfaitement tirer parti des subtilités du piano pour rendre les atmosphères variées de chaque mouvement, que ce soit la légèreté onirique de "Oiseaux tristes" ou la vivacité éclatante de "Alborada del gracioso". Cécile Ousset respecte le texte musical tout en y apportant une interprétation personnelle qui met en valeur la richesse harmonique et la couleur orchestrale des œuvres d'un incomparable Ravel.`,
    image: AB28,
    pdfLink: pdfAB28,
  },
  {
    id: 41,
    title: `1969 - Cécile Ousset - Claude Debussy, "Klavier"`,
    subtitle: ``,
    num: "8 25 917",
    ref: "Eterna",
    content: `Cette version Eterna ("Klavier", LP, Stereo) enregistrée en  1967, - reprise par Berlin Classics (1994, "Claude Debussy - Klavierwerke (Works For Piano)" - , montre combien le jeu nuancé et expressif de Cécile Ousset, convenait parfaitement aux compositions complexes et impressionniste du compositeur, à la fluidité, à sa dynamique subtile et sa grande attention à la couleur tonale.  Elle est ainsi à son meilleur dans « Poissons d’or », un extrait du deuxième livre des Images. Un enregistrement qui reste une interprétation intemporelle et exemplaire qui continue à résonner auprès des auditeurs et des critiques.`,
    image: AB29,
    pdfLink: pdfAB29,
  },
];
