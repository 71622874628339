import React from "react";
import Navbar from "./components/navbar/Navbar";
import Hero from "./layout/hero/Hero";
import About from "./layout/about/About";
import Featured from "./layout/featured/Featured"
import Footer from "./components/footer/Footer";
import ActiveSlider from "./layout/carousel/ActiveSlider";

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <About />
      <ActiveSlider />
      <Featured />
      <Footer />
    </>
  );
};

export default Home;
