import AB1 from "../assets/albums/AB1.webp";
import AB2 from "../assets/albums/DECCA.webp";
import AB3 from "../assets/albums/AB29.webp";
import AB4 from "../assets/albums/AB3.webp";
import AB5 from "../assets/albums/AB17.webp";

export const DiscographyData = [
  {
    title: "The Complete Warner Recordings",
    backgroundImage: AB1,
    link: "https://www.prestomusic.com/classical/products/9318530--cecile-ousset-the-complete-warner-recordings",
  },
  {
    title: "The Decca France Recordings",
    backgroundImage: AB2,
  },
  {
    title: "Gaspard de la nuit, Jeux d'eau, Mirroirs",
    backgroundImage: AB3,
  },
  {
    title: "75 Years Ysaÿe & Queen Elisabeth Piano Competition",
    backgroundImage: AB4,
  },
  {
    title: " Cécile Ousset, Günther Herbig, The Philharmonia",
    backgroundImage: AB5,
  },
];
