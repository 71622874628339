import React from "react";

const FeaturedVideo = ({ videoSrc }) => {
  return (
    <div className="flex justify-center items-center bg-black bg-opacity-50">
      <iframe
        className="w-[800px] h-[450px] rounded-lg max-sm:max-w-[90%] max-sm:h-[200px] max-lg:max-w-[90%]"
        src="https://www.youtube.com/embed/vMxJW1poEqk"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      ></iframe>
    </div>
  );
};

export default FeaturedVideo;
