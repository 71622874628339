import React from "react";
import AboutImg from "../../assets/about/image 1.png";

const Hero = () => {
  // Fonction pour empêcher les actions de copier, coller et glisser-déposer
  const preventDefaultActions = (e) => {
    e.preventDefault();
  };

  return (
    <section className="section bg-clear h-[100%] max-sm:flex-col" id="hero">
      <div className="gap-10 flex justify-center max-lg:flex-col-reverse max-sm:flex-col-reverse max-sm:max-w-[85%] mx-auto ">
        <div className="max-w-[700px] max-sm:max-w-[90%] max-sm:mx-auto max-xl:pl-6 max-sm:pl-0 max-lg:pl-0">
          <p className="text-black">
            Cécile Ousset est l'une de ces grandes dames du piano qui, enfant
            prodige, remporta son premier prix du Conservatoire de Paris à l'âge
            de quatorze ans, termina quatrième du Reine Elisabeth 1956, à 20
            ans, l'un des concours les plus prestigieux, et mena au long de plus
            de 2000 concerts à travers le monde, de 1956 à 2006, une double
            carrière de soliste et de concertiste avec ces grands maîtres que
            sont{" "}
            <span className="italic">
              Kurt Masur, Simon Rattle, Günther Herbig ou Neville Marriner.
            </span>
            <br />
            Une pianiste virtuose acquise à une grande interiorisation des
            œuvres qu'elle entendait interpréter, mais dont le talent ne fut
            reconnu que tardivement : le parcours comme la reconnaissance furent
            obtenus au long d'une carrière essentiellement internationale.
            <br />
            Les enregistrements qu'elle réalisa pour le label allemand Eterna,
            ainsi que pour Decca France, un partenariat s'étendant de 1971 à
            1976, initialiseront ce parcours exceptionnel, de même que sa
            traversée de{" "}
            <span className="italic">
              l'intégrale des variations de Beethoven
            </span>{" "}
            et <span className="italic">le deuxième concerto de Brahms</span>{" "}
            réalisé avec <span className="italic"> Kurt Masur </span>et
            l'Orchestre du Gewandhaus de Leipzig (1974) qui remportera le Grand
            Prix du Disque de l'Académie Charles Cros.
            <br />
            En 1978, après un concert avec l’Orchestre National de France dirigé
            par <span className="italic">Kurt Sanderling</span>, elle enchaînera
            une tournée dans toute l’Europe.
            <br /> L'année 1982 verra la sortie de son premier récital
            enregistré pour EMI Classics, un programme alliant
            <span className="italic"> les Tableaux d'une exposition</span> de
            Moussorgski et <span className="italic">Gaspard de la nuit </span>de
            Ravel, deux oeuvres qui témoignent de l'héritage musical
            franco-russe de la pianiste.
            <br />
            Cette année 1982 est aussi celle d’un concert symphonique donné au
            Royal Festival Hall, à Londres, au cours duquel elle interprète son
            fameux{" "}
            <span className="italic">
              Concerto pour piano n°2 de Brahms{" "}
            </span>{" "}
            sous la direction de <span className="italic">Kurt Masur</span>,
            point de départ de tournées triomphales à Hong-Kong, en Australie,
            en Nouvelle-Zélande, en Afrique du Sud.
            <br />
            En 2006, Cécile Ousset mettra fin à sa carrière publique sans grande
            annonce, avec modestie.
            <br />
            Seuls sa direction et ses amis proches étaient au courant lors de
            son dernier récital, au Wigmore Hall de Londres en décembre, un
            récital dédié à Chopin et à la musique française qu'elle affectionne
            tant.
            <br />
            2019 verra la parution pour la première fois en CD de son legs Decca
            France, des gravures du début des années soixante-dix (The Decca
            France Recordings - Eloquence).
            <br />
            En 2022, nouvelle rétrospective, 16 CD regroupés dans un coffret
            intitulé "The Complete Warner Recordings" sont publiés. Ces
            enregistrements EMI Classics couvrent les années 1982 à 1991 et
            marquent sa troisième et plus prestigieuse collaboration
            d’enregistrement.
          </p>
        </div>
        <div className="flex justify-center">
          <img
            src={AboutImg}
            alt=""
            className="h-[900px] object-cover max-sm:max-h-[400px] max-lg:max-h-[800px]"
            onContextMenu={preventDefaultActions} // Empêche le clic droit
            onDragStart={preventDefaultActions} // Empêche le glisser-déposer
            onMouseDown={preventDefaultActions} // Empêche d'autres actions de souris potentiellement indésirables
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
