import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Biography from "./pages/biography/Biography";
import Repertory from "./pages/repertory/Repertory";
import Discography from "./pages/discography/Discography";
import Medias from "./pages/medias/Medias";
import Contact from "./pages/contact/Contact";
import Concerts from "./pages/concerts/Concerts";
import TermsOfUse from './pages/termsofuse/TermsOfUse'
import PrivacyPolicy from "./pages/privacypolicy/Privacy Policy";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/biography" element={<Biography />} />
          <Route path="/repertory" element={<Repertory />} />
          <Route path="/discography" element={<Discography />} />
          <Route path="/medias" element={<Medias />} />
          <Route path="/concerts" element={<Concerts />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
