import React from "react";
import Navbar from "../../components/navbar/Navbar";
import ImgBiography from "../../assets/about/photo1.jpeg";
import ImgBiography2 from "../../assets/about/concours.jpg";
import ImgBiography3 from "../../assets/about/avantconservatoire.JPG";
import ImgBiography1 from "../../assets/about/picway.jpg";
import Footer from "../../components/footer/Footer";

const Biography = () => {
  // Fonction pour empêcher les actions de copier, coller et glisser-déposer
  const preventDefaultActions = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Navbar />
      <section className="section bg-black h-[100%]" id="biography">
        <div className="pt-[60px] flex flex-col items-center mx-auto">
          <h1 className="text-white opacity-90 max-sm:text-[30px]">
            BIOGRAPHIE
          </h1>
          <img
            className="max-w-[700px] pt-[70px] max-sm:max-w-[90%] max-sm:mx-auto max-lg:max-w-[70%]"
            src={ImgBiography3}
            alt="imagebiography"
            onContextMenu={preventDefaultActions} // Empêche le clic droit
            onDragStart={preventDefaultActions} // Empêche le glisser-déposer
            onMouseDown={preventDefaultActions} // Empêche d'autres actions de souris potentiellement indésirables
          />
          <div className="text-justify text-black">
            {/* INTRODUCTION */}
            <div className="flex flex-col items-center max-sm:max-w-[85%] max-sm:mx-auto max-lg:mx-auto">
              <div className="p-white max-w-[700px] pt-10 leading-9 max-sm:tracking-tight">
                <p className="font-light">
                  Cécile Ousset est née à Tarbes (Hautes-Pyrénées, France) en
                  1936. Dès sa plus tendre enfance, elle manifesta des dons
                  exceptionnels pour la musique.
                  <br />
                  <p className="">
                    Elle est la septième fille d'une famille dont le père,
                    officier supérieur, était lui-même un grand passionné de
                    musique classique. Doté de l'oreille absolue, la musique
                    était pour celui-ci plus qu'un second langage et c'est dans
                    cet environnement qu'il entendait éduquer ses enfants :
                    toutes feront l'apprentissage d'un instrument. Et parmi
                    celles-ci, Cécile fit preuve d'une telle précocité que
                    désormais le destin de toute cette famille se construisit
                    autour du don et du talent naissant de la jeune pianiste.
                    C'est à 5 ans qu'elle donne son premier récital et à
                    l'âge de huit ou neuf ans, la carrière de pianiste de
                    concert lui semble naturelle.
                  </p>
                  <p className="pt-5">
                    {" "}
                    Toute la famille fut ainsi conduite à Paris, en 1946, et la
                    rencontre décisive fut celle de Marcel Ciampi, professeur
                    renommé au Conservatoire National de Paris (1941-1961).
                    Proche de Claude Debussy, et du compositeur roumain Georges
                    Enesco qui lui dédia sa troisième sonate pour piano en 1938,
                    Ciampi avait formé des liens très étroits avec Yehudi
                    Menuhin et sa famille et des pianistes tels que Yvonne
                    Loriod, Eric Heidsieck et Jean-Paul Sevilla étudièrent
                    auprès de lui ...
                  </p>
                  <p className="pt-5">
                    Sollicité par le père de Cécile Ousset, Marcel Campi accepte
                    donc de prendre en charge la jeune pianiste de 10 ans, dans
                    son atelier de la salle Gaveau : ce sera une longue première
                    année préparatoire à l'entrée au Conservatoire, au cours de
                    laquelle, soutenue par Denyse Rivière, sa répétitrice, il
                    lui demandera d’arrêter de jouer pendant six mois pour
                    déconstruire sa technique, au profit de la tradition dite de
                    l'école russe, celle portée notamment par Anton Rubinstein
                    et Wanda Landowska, cette fameuse souplesse du bras et du
                    poignet, des épaules, jusqu’au bout des doigts, pour
                    délivrer le juste ton, la couleur, la musique. Cette
                    "puissance dans la profondeur" acquise dans cette année
                    charnière résonnera dans les tons robustes et cristallins de
                    l'ouverture des "Tableaux d'une exposition" de Moussorgski
                    enregistrés en 1982 ...
                  </p>
                  <p className="pt-5">
                    Ce fut un maître exigeant, se souvient-elle, extrêmement
                    sensible au jeu sur scène et poussant ses élèves à la limite
                    de leurs capacités, de leur potentiel : "Je me souviens
                    d'une émission de radio à laquelle j'ai participé à l'âge de
                    onze ans. Je devais jouer Mazeppa de Liszt, et si deux jours
                    avant l'émission, certains passages me posaient encore
                    problème, tout s'est mis en place d'un seul coup", marquant
                    ainsi pour le maître une première étape décisive dans la
                    progression de la jeune pianiste. Ensuite, j'ai simplement
                    joué, de plus en plus, ajoutera-t-elle, j'ai travaillé un
                    large répertoire et j'ai immédiatement étudié des œuvres
                    comme les Études transcendantales de Liszt, "mais cela a
                    pris du temps ..."
                  </p>
                </p>
              </div>
            </div>
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />

            {/* LES CONCOURS */}
            <div className="flex flex-col items-center mx-auto  max-sm:max-w-[85%] max-lg:max-w-[80%] max-sm:mx-auto">
              <div className="leading-10 max-sm:leading-8">
                <h4 className="font-bold font-primary text-white opacity-80 text-[40px] pt-10 pb-5 text-center max-sm:text-[20px]">
                  Le conservatoire & Les concours
                </h4>
              </div>

              <div className="flex justify-center">
                <img
                  className="max-w-[700px] pt-[40px] max-sm:pt-4  max-sm:max-w-[100%] max-lg:max-w-[100%]"
                  src={ImgBiography2}
                  alt="imagebiography"
                  onContextMenu={preventDefaultActions} // Empêche le clic droit
                  onDragStart={preventDefaultActions} // Empêche le glisser-déposer
                  onMouseDown={preventDefaultActions} // Empêche d'autres actions de souris potentiellement indésirables
                />
              </div>

              <div className="p-white max-w-[700px] text-justify text-white pt-10 leading-9 max-sm:tracking-tight">
                <p className="font-light">
                  Entrée au Conservatoire à 11 ans dans la classe de Marcel
                  Ciampi, Cécile Ousset en ressort en 1951 avec un Premier Prix,
                  elle a 14 ans. La reconnaissance internationale n'interviendra
                  que quelque vingt ans plus tard mais avec une même
                  préoccupation dans l'interprétation de son vaste répertoire,
                  la prédominance de l'émotion et de la fidélité au texte ...
                </p>
                <p className="pt-5 font-light">
                  Au sortir du Conservatoire, les concours internationaux
                  d'interprétation musicale, dont le nombre augmente
                  considérablement à partir des années 1950, constituent alors
                  la seule voie possible pour se faire connaître d'un public
                  encore inexpérimenté, pour émerger d'une communauté toujours
                  plus nombreuse et susciter des opportunités de concerts. Dès
                  1953, la quatrième place de Cécile Ousset au Concours
                  Marguerite Long-Jacques Thibaud conduisit Arthur Rubinstein,
                  membre mécontent du jury, à organiser quelques premiers
                  récitals à Paris pour la jeune pianiste, qui fera ses débuts
                  professionnels à la Salle Gaveau. En 1954, à 18 ans, elle
                  remporte le deuxième prix du concours international de Genève;
                  puis en 1955, le premier grand prix du concours international
                  Viotti.
                </p>
                <p className="pt-5 font-light">
                  L'année 1956 se révèle une année exceptionnelle pour le
                  Concours musical international Reine Élisabeth de Belgique au
                  cours duquel elle remporte le quatrième prix et qui voit la
                  participation de pianistes qui ont acquis depuis une renommée
                  incontournable, Vladimir Ashkenazy (Premier prix), John
                  Browning, Andrzej Czajkowski, Lazar Berman, Tamás Vásáry,
                  Stanislav Knor, Claude Coppens, György Banhalmi, Peter Frankl.
                  Pour sa part, on lui doit ici une des meilleures
                  interprétations du Concerto de R. Defossez et un Concerto en
                  fa mineur de Chopin : "elle est entrée dans la pensée du
                  maître", écrira Paul Tinel (Le Soir, juin 1956). Une nature
                  "en possession d'un enviable acquis technique et sa nature est
                  vive, intelligente, dominatrice et d'une fougeuse spontanéité"
                  (Bruxelles). Mais atteindre la notoriété est alors un long
                  combat, "tous les artistes doivent se battre pour être
                  reconnus..."
                </p>
                <p className="pt-5 font-light">
                  La carrière pianistique de Cécile Ousset va désormais se
                  construire au fil des Concerts, en solo ou avec orchestre, et
                  des Concours, qui vont constituer autant d'objectifs que
                  d'étapes dans l'élaboration de son répertoire. C'est dans le
                  sillage des Jeunesses Musicales de France, créées par René
                  Nicoly en 1944, qu'un grand nombre de concerts seront
                  organisés durant ces deux premières décennies, 1950-1960. Sa
                  réussite lors des concours et sa réputation en tant qu'enfant
                  prodige semblait alors susciter des critiques admiratives,
                  ainsi de celle rédigée en 1956, lors d'un concert qui comporte
                  déjà le difficile Concerto en ut mineur de Rachmaninoff :
                  "salle archi-comble, jeu brillant, dogts ailés, vélocité
                  prodigieuse", écrit Bernard Gavoty, alias Clarendon, dans les
                  colonnes du Figaro au temps de sa splendeur, Alors que les
                  premières récitals sont français, belges ou italiens, en 1959,
                  à 23 ans, Cécile Ousset emporte le premier prix du concours
                  international Busoni (deuxième prix), et en 1962, à 26 ans, le
                  prix Van Cliburn (quatrième prix).
                </p>
                <p className="pt-5 font-light">
                  En avril 1962, elle donne son premier récital au Wigmore Hall
                  de Londres (suite n°2 en fa, de Haendel, Sonate KV 282, de
                  Mozart, Variations sur un thème de Paganini, de Brahms,
                  Miroirs, de Ravel, Bourrée fantasque, de Chabrier, Etude en
                  forme de valse, de Saint-Saëns), et en août 1962, enregistre
                  le 4e Concerto de Beethoven avec l'orchestre philarmonique de
                  la RTF. Debussy, Ravel, Fauré, Chabrier sont déjà au programme
                  de certains de ses concerts. L'année 1963 est une année de
                  tournées en Espagne, en Grèce, au Liban, en Turquie, en
                  Allemagne, "Gaspart de la nuit", de Ravel est joué à
                  Aix-la-Chapelle, puis à Milan en 1964. Cette même année voit
                  s'organiser une première tournée en URSS, la musique française
                  en constitue le coeur, puis en Allemagne de l'Est, en
                  Tchécoslovaquie , et en Grèce, en 1965, jusqu'en Amérique du
                  Sud (Brésil, Uruguay, Chili, Mexique). En 1966, l'Allemagne de
                  l'Est est de nouveau au programme : en mars, sous la direction
                  de Kurt Sanderling et avec le Sinfonieorchester, elle
                  interprète à Berlin, le Concerto en sol de Mendelssohn. 1966
                  est aussi l'année d'une tournée qui couvre l'Ouest des
                  Etats-Unis, la Polynésie et le Japon. Petite incursion à Paris
                  avec une émission de radio enregistrée avec l'orchestre
                  National, sous la driection de Jacques Houtmann, puis un
                  concert au Théâtre des Champs-Elysées avec l'orchestre
                  Colonne, en juillet et octobre 1966 ...
                </p>
                <p className="pt-5 font-light">
                  En 1967, Cécile Ousset enregistre à Dresde son premier disque
                  consacré à Debussy, sous le label Eterna. Le disque suivant,
                  enregistré sous ce même label en mars 1971, comportera des
                  oeuvres de Maurice Ravel, Miroirs, Gaspard de la nuit et Jeux
                  d'eau. 1967 est aussi l'année d'une tournée en URSS, de Moscou
                  à Tbillissi, et de sa première apparition aux Etats-Unis
                  depuis sa participation au concours Van Cliburn, à Fort Worth,
                  Texas, en 1962 : un récital au Town-Hall de New York, qui
                  suscitera un article du New York Times le 23 novembre; elle y
                  joue les Variations (Mozart), le Carnaval op.9 (Schumann),
                  quatre Etudes de Debussy et Gaspard de la nuit, de Ravel. Deux
                  tournées vont principalement marquer l'année 1968 (avril), en
                  Pologne et en RDA, puis ce sera la Roumanie (novembre), tandis
                  qu'elle donne en décembre, à Paris, un Récital à la Maison de
                  l'ORTF.
                </p>
                <p className="pt-5 font-light">
                  Mais 1968 est surtout l'année de la rencontre de Cécile Ousset
                  et d'un Kurt Mazur qui vient de prendre en main la direction
                  du Philharmonic de Dresde avant d'être nommé, en 1970,
                  Kapellmeister d'une des premières formations musicales
                  d'Europe, le Leipzig Gewandhaus. De nombreux concerts vont
                  naître de cette rencontre, notamment, en avril 1968, le
                  Concerto pour piano KV453 de Mozart et le Concerto de Grieg;
                  en mars 1969, les Variations symphoniques de César Franck et
                  le Concerto en Mi de Chopin; en novembre, le Concerto n° 2, de
                  Brahms; en décembre 1970, le Concerto KV488, de Mozart, et le
                  Concerto n° 2, de Saint-Saëns; en mars 1971, les Variations
                  Symphoniques, de Franck, et le Concerto en sol, de Ravel ...
                </p>
              </div>
            </div>
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />

            {/* LE CHEMIN VERS LA RECONNAISSANCE*/}
            <div className="flex flex-col items-center max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white opacity-80 text-[40px] pt-10 pb-5 text-center max-sm:text-[20px] ">
                Le chemin vers la reconnaissance
              </h4>
              <img
                className="max-w-[700px] pt-[40px] max-sm:pt-4 max-sm:max-w-[90%] max-sm:mx-auto max-lg:max-w-[70%]"
                src={ImgBiography1}
                alt="imagebiography"
                onContextMenu={preventDefaultActions} // Empêche le clic droit
                onDragStart={preventDefaultActions} // Empêche le glisser-déposer
                onMouseDown={preventDefaultActions} // Empêche d'autres actions de souris potentiellement indésirables
              />
              <div className="p-white max-w-[700px] text-justify pt-5 leading-9">
                <p className="font-light max-w-[700px] text-justify pt-5 leading-9 mx-auto max-sm:tracking-tight	">
                  Le début des années 1970 est marqué par de très nombreux
                  concerts réalisés tant en Allemagne qu'en RDA, un récital à
                  Tarbes en novembre 1972 qui permet un temps de renouer avec le
                  passé, quelques brèves apparitions en France, et des tournées
                  en Tchécoslovaquie (1973, 1976) et en URSS (mai 1973, octobre
                  1975).
                </p>
                <p className="font-light max-w-[700px] text-justify pt-5 leading-9 mx-auto max-sm:tracking-tight	  ">
                  La carrière de Cécile Ousset va désormais s'accélérant. C'est
                  d'abord la conclusion d'un contrat avec Decca France, un
                  partenariat qui va s'étendre de 1971 à 1976, avec un premier
                  enregistrement en octobre 1972 puis en septembre 1973 des
                  Variations de Paganini, de Brahms, du Carnaval, de Schumann,
                  puis des Dix pièces pour piano opus 12 de Prokofiev.
                </p>
                <p className="font-light pt-5">
                  Parallèlement, seront produits deux enregistrements réalisés
                  chez Eterna, le premier, en avril 1973, un ensemble de Sonates
                  de Mozart (KV330 à KV333), et le second, le phénoménal
                  Concerto pour piano n°2 de Brahms avec Kurt Masur au pupitre
                  du Gewandhaus de Leipzig, un enregistrement qui glane un Grand
                  Prix de l’Académie Charles Cros. En avril-ma 1976 et janvier
                  1977, les derniers enregistrements de Cécile Ousset de cette
                  période Decca comprennent une intégrale des variations de
                  Beethoven, en deux volumes, qui constituent la seconde
                  intégrale réalisée après celle d'Alfred Brendel enregistrée
                  dix ans plus tôt pour Vox : la pianiste remporte ici
                  l'adhésion critique par ses dynamiques et ses attaques avec
                  une palette de nuances remarquable.
                </p>
                <p className="font-light max-w-[700px] text-justify  pt-5 leading-9 mx-auto max-sm:tracking-tight">
                  En termes de concerts, avril 1973 marque la première rencontre
                  de Cécile Ousset avec Günther Herbig, alors Directeur musical
                  de l'Orchestre philharmonique de Dresde, et qui sera de 1977 à
                  1983 à la tête du Berliner Sinfonie-Orchester à Berlin-Est :
                  sont interprétés le Concerto en Fa, de Chopin, et le 4e
                  Concerto, de Beethoven. Novembre 1975 suscitera le Concerto
                  KV467, de Mozart, et le Concerto en mi bémol, de Litszt.
                </p>
                <p className="font-light pt-5">
                  Mai 1975, Cécile Ousset est à Londres et joue au Queen
                  Elisabeth Hall, la Sonate op. 111, de Beethoven, les Cinq
                  pièces op. 3, de Rachmaninov, l'Etude en forme de valse, de
                  Saint-Saëns, les 3 Gymnopédies de Satie et les fameuses
                  Variations de Paganini, de Brahms. Ell y reviendra en février
                  1977, pour donner Les Variations héroïques op. 35, de
                  Beethoven. En mars 1976, elle est à Prague, et interprète
                  notamment la Sonate KV333, de Mozart, les 4 Etudes op. 10, de
                  Chopin, et sa Ballade n° 4, la 6e Nocturne et le 2e Impromptu,
                  de Fauré.
                </p>
                <p className="font-light pt-5">
                  Mars 1977 inaugure la première tournée de Cécile Ousset en
                  Afrique du Sud. Un premier concert, réalisé à Cape Town, sous
                  la direction de Mendi Rodan conduisant le Cape Town Symphony
                  Orchestra, associe le Concerto KV488, de Mozart, au Concerto
                  pour la main gauche, de Ravel.
                </p>
                <p className="font-light pt-5">
                  Mai-Juin 1977, à Dresde, c'est à nouveau avec Kurt Masur et la
                  Philharmonic que la pianiste interprète le Concerto en sol
                  mineur, de Mendelssohn, et le n° 2, de Rachmaninov; puis à
                  Leipzig, avec le Gewandhaus, le 2e Concerto, de Saint-Saëns.
                </p>
                <p className="font-light pt-5">
                  En 1978, Cécile Ousset poursuit ses grandes tournée dans toute
                  l’Europe (de Prague à Berlin, München ou Bristol) après un
                  concert avec l’Orchestre National de France dirigé par Kurt
                  Sanderling en janvier, à Paris : au programme, le 4e Concerto
                  , de Beethoven.
                </p>
                <p className="font-light pt-5">
                  La décennie s'achève avec, en mars 1979, une grande tournée de
                  trois mois en Australie, le premier concert est donné à
                  Sydney, le Concerto n° 2, de Rachmaninov.
                </p>
              </div>
            </div>
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />

            {/* LA CARRIERE */}
            <div className="flex flex-col items-center max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold text-white opacity-80 font-primary text-[40px] pt-10 pb-5 text-center max-sm:text-[20px]">
                La carrière internationale
              </h4>
              <div className="flex justify-center">
                <img
                  className="max-w-[700px] pt-[40px] max-sm:pt-4  max-sm:max-w-[90%] max-lg:max-w-[80%]"
                  src={ImgBiography}
                  alt="imagebiography"
                  onContextMenu={preventDefaultActions} // Empêche le clic droit
                  onDragStart={preventDefaultActions} // Empêche le glisser-déposer
                  onMouseDown={preventDefaultActions} // Empêche d'autres actions de souris potentiellement indésirables
                />
              </div>
              <div className="p-white max-w-[700px] text-justify text-white pt-5 max-sm:tracking-tight">
                <p className="font-light pt-5">
                  En 1980, grâce à un producteur britannique, producteur qu'elle
                  recherchait en vain depuis bien des années pour structurer et
                  orienter sa carrière, Cécile Ousset remplace la grande
                  pianiste Martha Argerich au Festival d’Edimbourg, en Ecosse :
                  le récital triomphal qu'elle donne lui ouvre les portes du
                  Royaume-Uni (Bristol, Cardiff, Bournemouth, Birmigham), tout
                  en continuant à sillonner l'Europe, de Copenhague à Prague, de
                  Moscou à Berlin. Son récital d'Edimbourg, le 19 août 1980,
                  comportait notamment la 4e Ballade, de Chopin, la Rhapsodie n°
                  12, de Liszt, Estampes et Préludes, de Debussy. Le Concerto
                  pour la main gauche, de Ravel, est au programme d'un concert
                  donné en mars 1981 avec Kurt Sanderlig à la tête du Beliner
                  Sinfonieorchestra.
                </p>
                <p className="font-light pt-5">
                  En janvier 1982, première rencontre de la pianiste avec le
                  violoniste et chef d'orchestre britannique, fondateur de
                  l'Academy of St Martin in the Fields, Neville Mariner : au
                  programme, au Royal festival de Londres, le Concerto en fa, de
                  Gershwin. En 1982, Cécile Ousset refait parler d’elle, à
                  Londres cette-fois, lors d’un concert symphonique au Fairfield
                  Hall, de Croydon, au cours duquel elle interprète le Concerto
                  pour piano n°2 de Brahms sous la direction de Kurt Masur et le
                  Royal Philarmonic Orchestra. Puis une seconde rencontre, toute
                  aussi capitale, avec un Simon Rattle qui, à la tête du City of
                  Birmingham Symphony Orchestra (CBSO), va, de 1980 à 1998, se
                  révéler aux critiques et au public. Enfin, toujours à Londres,
                  en juillet, la pianiste joue au Proms, à l'Albert Hall, avec
                  le Royal Philharmonic Orchestra dirigé par Louis Frémaux, pour
                  jouer le Concerto n°2, de Saint-Saëns; en septembre, elle y
                  interprètera le Concerto de Schumann, avec le BBC National
                  Orchestra of Wales sous la direction de Tadaaki Otaka : un
                  concert qui marque une nouvelle étape dans la progressive
                  consolidation de sa réputationn au Royaume-Uni...
                </p>
                <p className="font-light pt-5">
                  Dans la continuité de ces succès, Cécile Ousset signe un
                  nouveau contrat avec EMI Records, - label auquel elle sera
                  fidèle tout au long des années 1980 (1982-1991). Et l'année
                  1982, année particulièrement faste, voit la sortie de son
                  premier récital enregistré pour EMI Classics : janvier, un
                  programme alliant les Tableaux d'une exposition, de
                  Moussorgski, et Gaspard de la nuit, de Ravel. En mars, le
                  Concerto n°2, de Saint-Saëns, et le Concerto n°1, de Liszt,
                  avec l'orchestre de Birmingham que dirige Simon Rattle. C'est
                  début d'une collaboration fructueuse qui donnera naissance à
                  plusieurs concerts et enregistrements, dont le deuxième de
                  Rachmaninov et ses varíations sur un thème de Paganíni, en mai
                  1984, ainsi que les deux concertos de Ravel.
                </p>
                <p className="font-light pt-5">
                  Les œuvres du répertoire français demeurent un élément
                  primordial du répertoire de Cécile Ousset. Un tout premier
                  récital entièrement consacré à la musique française et
                  effectué pour Decca en 1971 sera suivi d'un deuxième récital
                  en 1983, et précédé d'un enregistrement EMI en juillet 1982. À
                  la place des Études de Debussy, dont quelques extraits
                  figuraient déjà sur le récital pour Decca, la pianiste se
                  tourne cette fois-ci vers les Estampes du même compositeur,
                  les associant à une poignée d'oeuvres de Fauré, Ravel,
                  Chabrier, Satie et Saint-Saëns. Le deuxième Impromptu de Fauré
                  est un exemple si représentatif d'un brio naturel qui
                  contraste merveilleusement avec la ﬁne musicalité de la
                  pianiste. Et le temps d'un concert, à Paris, Salle Pleyel, en
                  novembre 1982, Cécile Ousset partagera avec l'orchestre de
                  Paris, sous la direction du chef d'orchestre coréen Myung-Whun
                  Chung, son grand Deuxième Concerto de Rachmaninov ..
                </p>
                <p className="font-light pt-5">
                  D'autres gravures virtuoses suivront, le concerto de Poulenc
                  et le troisième de Rachmaninov avec Rudolph Barshaï et
                  l'orchestre symphonique de Bournemouth en novembre 1982, dont
                  l'interprétation colorée de la soliste sera particulièrement
                  appréciée. Autre collaboration entre Cécile Ousset et le grand
                  Rudolph Barshaï, si étroitement lié à Chostakovitch, le
                  Concerto n°3 de Prokofiev interprété à Portshmouth puis au
                  Barbican, à Londres, en octobre 1983. Puis en juillet 1984, le
                  concerto de Grieg et le premier de Mendelssohn avec Sir
                  Neville Marriner et le London Symphony Orchestra, joués par
                  ailleurs au Barbican de Londres et qui seront acclamés par la
                  critique internationale. Suivront de Rachmaninov, le Concerto
                  No 3 avec le Philharmonia Orchestra, sous la direction de
                  Gunter Herbig, les deux concertos de Ravel avec le l'orchestre
                  symphonique de Birmingham de Sir Rattle, les Concertos de
                  Tchaïkovski et de Schumann avec le London Philharmonic de Kurt
                  Masur.
                </p>
                <p className="font-light pt-5">
                  En 1984, Cécile Ousset fait ses débuts aux États-Unis comme
                  soliste avec le Philarmonique de Los Angeles (Gunther Herbig,
                  3e Concerto de Rachmaninov) et l’Orchestre du Minnesota (à
                  Minneapolis, avec Neville Marriner, le Concerto de Gershwin),
                  et y retournera de nombreuses années pour des concerts avec
                  l’Orchestre symphonique de Boston (janvier 1985, Kurt Masur),
                  de San Diego, et l’Orchestre symphonique national
                  (Washington).
                </p>
                <p className="font-light pt-5">
                  Liszt et Chopin, deux révolutionnaires de la tradition
                  pianistique du XIXe siècle, occuperont, eux aussi, une place
                  importante dans le répertoire de Cécile Ousset. Une gravure
                  EMI consacrée au compositeur hongrois paraît en octobre1984 où
                  se côtoient les six Grandes Études de Paganíní, œuvre
                  représentative du proﬁl virtuose et la Sonate en si mineur,
                  testament pianistique du romantisme qui fut dédié à Robert
                  Schumann. D'une éloquence qui fait merveille, la pianiste
                  s'attelle aux sommets vertigineux de l'oeuvre, avec ce souffle
                  que l'on retrouvait déjà dans les enregistrements des
                  concertos de Grieg et de Mendelssohn réalisée l'année
                  précédente.
                </p>
                <p className="font-light pt-5">
                  La carrière internationale de Cécile Ousset prend alors une
                  nouvelle dimension. Après une nouvelle tournée en Afrique du
                  Sud (septembre 1984), c'est la même année qu'elle réalise une
                  grande tournée au Japon : son premier concert à Tokyo est
                  dédié au Concerto n°2 de Saint-Saëns, avec le Tokyo
                  Philharmonic Orchestra sous la direction de Tadaaki Odaka
                  (octobre). Fauré, Debussy, Ravel, Moussorgsky et bien entendu
                  Rachmaninov seront au programme de six récitals et concerts,
                  avant une représentation donnée à Hong-Kong, en novembre et un
                  retour à Londres, au Festival Hall, puis Belfast. L'ensemble
                  orchestral de Paris, sous la direction d'Armin Jordan, la
                  reçoit à Paris, salle Pleyel, pour interpréter en décembre le
                  Concerto n°3, de Beethoven. Après un Concerto n°1 de Brahms,
                  interprété à Londres sous la direction de Kurt Masur, les
                  concerts se succèdent en 1985, au Luxembourg, en Italie, au
                  Canada, en Finlande, en Suisse, mais aussi à Toulouse et à
                  Lyon. L'année 1986 débute Détroit et à Baltimore, pour se
                  poursuivre au Japon, une nouvelle fois.
                </p>
                <p className="font-light pt-5">
                  En août 1985, Cécile Ousset consacre une suite de gravures
                  importantes à Debussy et Ravel. Les œuvres de Debussy -
                  l'intégralité des Préludes et des Images, les deux Arabesques,
                  L'Isle joyeuse, Pour le piano et la Suite Bergamasque -
                  s'étalent sur deux enregistrements parus en 1986 et en 1989.
                  L'afﬁnité de Cécile Ousset pour Debussy est considérable, et
                  sait ainsi exploiter chaque timbre et sonorité de
                  l'instrument, créer des images vives qui rayonnent de la
                  partition, "le bruit de la mer, la courbe d'un horizon, le
                  vent dans les feuilles, le cri d'un oiseau déposent en nous de
                  multiples impressions. Et tout d'un coup, sans qu'on y
                  consente le moins du monde, l'un de ces souvenirs se répand
                  hors de nous et s'exprime en langage musical", pour citer un
                  entretien célèbre de Debussy avec Henry Malherbe. Quant à
                  l'œuvre de Ravel, la pianiste conﬁera au disque les Valses
                  nobles et sentimentales, Jeux d'eau, Menuet sur le nom de
                  Haydn, Sonatíne, Miroirs, Pavane pour une enfante défunte, Le
                  Tombeau de Couperin et les deux concertos aux côtés de Sir
                  Simon Rattle et l'orchestre symphonique de Birmingham.
                </p>
                <p className="font-light pt-5">
                  1990 (février), pour ses derniers enregistrements sous le
                  label EMI, Cécile Ousset retrouve Kurt Masur pour interpréter
                  avec le London Philharmonic Orchestra, le Premier Concerto de
                  Tchaïkovski et celui de Schumann. Une rare tendresse conduira
                  le Concerto de Schumann, l'une de ses œuvres préférées avec
                  les concertos de Brahms, et qui sera donné durant ce même
                  début d'année, à Londres et à Leipzig. Puis, aux côtés de
                  Günther Herbig et du Philharmonia Orchestra, la pianiste grave
                  le troisième de Rachmaninov qu'elle qualiﬁe de "défi ultime"
                  pour une interprète féminine en raison des grandes octaves qui
                  parsèment l'oeuvre, laquelle est publiée avec la Sonate de
                  Rachmaninov sur le même disque de 1990. Les deux concertos de
                  Ravel seront par suite interprétés à Birmigham avec Simon
                  Rattle, une année qui se poursuit entre Madrid et Londres, et,
                  en octobre, une nouvelle tournée au Japon et Hong-Kong. Cécile
                  Ousset continue durant cette décennie 1990 à parcourir le
                  monde, Rachmaninov est au centre des concerts partagés avec
                  Gunter Herbig en 1993, à Londres; le Concerto en sol de Ravel
                  domine les représentations données au Palau de la Musica de
                  Barcelone, avec Edmon Colomer (janvier 1994); le concerto n°1
                  de Mendelssohn, le Concerto de Grieg ou le Concerto de Brahms
                  n°2, sous la direction de Claude Peter Flor sont joués tour à
                  tour à Rotterdam (novembre 94), à Zurich (mai 1995) et à
                  Berlin (septembre 1995); des récitals joués à Istanbul ou à
                  Düsseldorf succèdent à des concerts à Jérusalem, avec David
                  Shallon et le Jersalem Symphony Orchestra (février 1995), ou à
                  New-York, avec Kurt Masur à la tête du New-York Philharmonic
                  pour y donner la Burlesque pour piano et orchestre de Richard
                  Strauss (septembre 1995). Prague, en janvier 1996, suscite un
                  concert et un récital au programme duquel on retrouve la
                  Pathétique, de Beethoven, la Sonate op. 35, de Chopin, Valses
                  Nobles, de Ravel, Etude en forme de valse, de Saint-Saëns,
                  Thème et Variations, de Fauré. En février 1996, Cécile Ousset
                  effectue une tournée en Afrique du Sud : elle y donne, avec
                  l’Orchestre symphonique national, une représentation du
                  deuxième concerto pour piano de Saint-Saens, qui rencontre un
                  succès indéniable à Johannesburg. Trois récitals s'ensuivent
                  en août à Copenhague; le concerto n°2 de Brahms est joué à
                  Modena et à Ferrara sous la direction d'Emmanuel Krivine. En
                  1998, Cécile Ousset revient au Royaume-Uni pour des concerts
                  avec la Symphonie de Bournemouth et la Sinfonia du Nord,
                  suivis d’un récital au Wigmore Hall au printemps. Une critique
                  de son interprétation en mai du concerto Schumann avec
                  l’orchestre du Gewandhaus de Leipzig sous la direction de Jiri
                  Belohlavek écrira : "Cécile Ousset a cherché le cœur de
                  l’œuvre et de son compositeur avec un succès retentissant.
                  Même dans la cadence du 1er mouvement, Ousset a réussi à
                  démêler des détails inédits. Ses cascades de notes raffinées,
                  son phrasé cantabile et son immersion dans le monde onirique
                  de Schumann - autant de talents qui lui ont permis de révéler
                  les meilleures qualités de la musique..."
                </p>
                <p className="font-light pt-5">
                  En 1999, Cécile Ousset enregistre " Rhapsody in Blue" de
                  Gershwin et son "Concerto pour piano en fa" avec le Stuttgart
                  Radio Orchestra sous la direction de Neville Marriner pour
                  Capriccio, tandis que BBC Music Worldwide sort en mars 1999 un
                  CD d’un récital Chopin/Ravel enregistré en direct au Wigmore
                  Hall. 1999 voit de même le retour de Cécile Ousset à la
                  Philharmonique de New York (sa troisième visite), cette fois
                  pour jouer Saint-Saens No. 2, sous la direction de Fabio
                  Luisi. Elle interprétera également le G Major Concerto de
                  Ravel au St David’s Hall de Cardiff avec le BBC National
                  Orchestra of Wales, et le Piano Concerto No. 2 de Beethoven
                  avec le Royal Philharmonic, tous deux dirigés par Matthias
                  Bamert. Elle se produira également avec l’Israel Chamber
                  Orchestra et l’Orchestre National de Bordeaux Aquitaine.
                </p>
              </div>
            </div>
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />

            {/* MASTERCLASS */}
            <div className="flex flex-col items-center max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white opacity-80 text-[40px] pt-10 pb-5 text-center  max-sm:text-[20px]">
                Masterclass & Jurys
              </h4>
              <div className="p-white max-w-[700px] text-justify pt-5 max-sm:tracking-tight">
                <p className="font-light pt-5">
                  Soutenir le talent de jeunes pianistes qui ont déjà fait la
                  preuve de leurs capacités musicales exceptionnelles et
                  prometteuses dans la pratique de leur art est une
                  préoccupation majeure à laquelle Cécile Ousset contribue
                  fortement dès les années 1990 et en ce début des années 2000.
                  <br />
                  Cette contribution sera menée sur deux plans :
                  <br />
                  <span>
                    • La création et l'animation de masterclasss et la
                    participation à des jurys internationnaux.
                  </span>
                  <br />
                  <span>
                    • En terme de masterclass, elle organisera à Puycelsi dans
                    le Tarn (France) de nombreuses sessions qui seront menées
                    dans les années 1990.
                  </span>
                </p>
                <p className="font-light pt-5">
                  Elle sera ainsi membre de nombreux jurys de concours
                  internationaux, prestigieux, tels que le "Cliburn" (The Van
                  Cliburn International Piano Competition), en 1993 et 1997, le
                  "Rubinstein" (Arthur Rubinstein International Piano Master
                  Competition), le "Long-Thibaud", ou le prestigieux Concours
                  international de piano de Leeds qui entretient des liens
                  étroits avec le Yaltah Menuhin Memorial Fund dont elle est
                  "Honorary Patron" depuis 2006.
                </p>
                <p className="font-light pt-5">
                  En 2006, Cécile Ousset a mis fin à sa carrière publique lors
                  d'un dernier récital, au Wigmore Hall de Londres en décembre,
                  un récital dédié à Chopin et à la musique française qu'elle
                  affectionne tant. Les nombreux enregistrements réalisés par
                  Cécile Ousset vivent désormais dans le reconditionnement sans
                  fin et les compilations d'une exceptionnelle carrière
                  internationale ...
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Biography;
