import React, { useState, useEffect } from "react";
import ImgRepertory from "../../assets/repertory/testpic2.jpg";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Repertory = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = ImgRepertory;
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <>
      <Navbar />
      <section
        className={`h-screen flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat max-sm:h-screen max-lg:h-screen overflow-y-auto transition-opacity duration-700 ${
          imageLoaded ? "opacity-100" : "opacity-0"
        }`}
        style={{ backgroundImage: `url(${ImgRepertory})` }}
      >
        <div className="pt-[150px] h-screen flex flex-col items-center mx-auto max-sm:pt-[115px]">
          <h1 className="text-white max-sm:text-[30px]">RÉPERTOIRE</h1>
          <div className="pt-[70px] text-justify text-white max-sm:max-w-[85%]">
            <h3 className="text-[24px] text-center max-sm:text-[22px]">
              Un très vaste répertoire
            </h3>
            <p className="black-shadow max-w-[700px] text-white pt-5 leading-9 mx-auto">
              Les pianistes qui portent quelque intérêt historique ou
              intellectuel à leur art connaissent beaucoup de musique qu’ils ne
              présentent jamais au public. L'artiste aime découvrir, mais de
              nombreuses œuvres prennent des années à être absorbées et
              assimilées. La question qui revient fréquemment, tant pour
              celui-ci que pour l'artiste, est celle du répertoire, de son
              choix, de son évolution.
              <br /> La forte technique de Cécile Ousset, basée sur la méthode
              russe enseignée par Ciampi, lui confère une tonalité nette et
              robuste qui l'oriente logiquement vers les poids lourds virtuoses
              de la littérature de concertos, tels que{" "}
              <span className="italic font-bold">
                Rachmaninov, Saint-Saëns, Liszt, Prokofiev, Grieg, Tchaikovsky.
              </span>
              <br />
              Mais ce sont aussi et surtout les œuvres du répertoire français
              qui occuperont une place importante dans les programmes de la
              pianiste.
              <br /> Elle donnera en 1987 cinq récitals de musique française au
              Barbican de Londres, et ses interprétations de certaines des
              pièces solo de Saint-Saëns, mais aussi de Chopin et de Schumann,
              sont incontestées. Tout en enregistrant et en se produisant dans
              de très nombreux concerts, Cécile Ousset ne cessera pas son
              apprentissage de nouvelles œuvres...
              <br /> Et si le volumineux répertoire interprété et enregistré
              pour DECCA dans les années 1970 et pour EMI par Cécile Ousset
              entre 1982 et 1990 n’a pas nécessairement été celui qu’elle
              voulait jouer, ce fut celui que le public voulait entendre de sa
              part...
            </p>

            <div className="pt-[90px] h-screen flex flex-col items-center">
              <h2 className="text-[30px] max-sm:text-[20px] text-center">
                PIANO SOLO
              </h2>
              <ul className="black-shadow list-disc p-5 mb-10 max-sm:text-start">
                <li>
                  Brahms,<span className="italic"> Variations de Paganini</span>
                </li>
                <li>
                  Robert Schumann,<span className="italic"> Carnaval</span>
                </li>
                <li>
                  Camille Saint-Saëns,<span className="italic"> Etudes</span>
                </li>
                <li>
                  Beethoven,
                  <span className="italic"> les Variations pour piano</span>
                </li>
                <li>
                  Claude Debussy,<span className="italic"> Etudes</span>
                </li>
                <li>
                  Claude Debussy,
                  <span className="italic"> Suite Bergamasque</span>
                </li>
                <li>
                  Claude Debussy,<span className="italic"> Estampes</span>
                </li>
                <li>
                  Erik Satie,<span className="italic"> Gymnopédies</span>
                </li>
                <li>
                  Emmanuel Chabrier,
                  <span className="italic"> Pièces pittoresques</span>
                </li>
                <li>
                  Fauré,<span className="italic"> Impromptus</span>
                </li>
                <li>
                  Prokofiev,
                  <span className="italic"> 10 Pièces pour piano</span>
                </li>
              </ul>
              <h2 className="text-[30px] max-sm:text-[20px] text-center">
                OEUVRES CONCERTANTES
              </h2>
              <ul className="black-shadow list-disc p-5 mb-10 max-sm:text-start">
                <li>
                  Schumann,
                  <span className="italic"> concerto en la mineur</span>
                </li>
                <li>
                  Brahms,<span className="italic"> les deux concertos</span>
                </li>
                <li>
                  Rachmaninov,
                  <span className="italic"> les trois concertos</span>
                </li>
                <li>
                  Rachmaninov,
                  <span className="italic">
                    {" "}
                    Rhapsodie sur un thème de Paganini
                  </span>
                </li>
                <li>
                  Saint-Saëns,
                  <span className="italic"> concerto pour piano no 2</span>
                </li>
                <li>
                  Liszt,<span className="italic"> concerto no 1</span>
                </li>
                <li>
                  Ravel,<span className="italic"> deux concertos</span>
                </li>
                <li>
                  Ravel,
                  <span className="italic">
                    {" "}
                    Pavane pour une infante défunte
                  </span>
                </li>
                <li>
                  Prokofiev,
                  <span className="italic"> concerto pour piano n°3</span>
                </li>
                <li>
                  Grieg,
                  <span className="italic">
                    {" "}
                    Piano Concerto In A Minor, Op.16
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Repertory;
