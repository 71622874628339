import React from "react";
import Navbar from "../../components/navbar/Navbar";
import MediasVideo from "../../components/video/MediasVideo";
import FeaturedVideo from "../../components/video/FeaturedVideo";
import Footer from "../../components/footer/Footer";

const Medias = () => {
  return (
    <>
      <Navbar />
      <section className="section bg-black h-[100%]" id="multimedia">
        <div className="pt-[60px] flex flex-col items-center mx-auto">
          <h1 className="text-white max-sm:text-[30px]">VIDÉOS</h1>
          <div className="pt-16">
            <FeaturedVideo />
            <hr className="my-6 border-white sm:mx-auto dark:border-white xl:my-[80px] max-lg:my-[70px] max-sm:my-[40px] xl:max-w-[400px] max-lg:max-w-[300px] max-sm:max-w-[150px] mx-auto" />
          </div>
          <div>
            <MediasVideo videoSrc="https://www.youtube.com/embed/gitqKwMNfQE" />
            <hr className="my-6 border-white sm:mx-auto dark:border-white xl:my-[80px]  max-lg:my-[70px] max-sm:my-[40px] xl:max-w-[400px] max-lg:max-w-[300px] max-sm:max-w-[150px] mx-auto" />
          </div>
          <div>
            <MediasVideo videoSrc="https://www.youtube.com/embed/WzYDYZp7G-A" />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Medias;
