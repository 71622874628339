import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FreeMode, Pagination, Navigation } from "swiper/modules";
import { DiscographyData } from "../../constants";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const ActiveSlider = () => {
  return (
    <div className="bg-black flex items-center justify-center flex-col h-screen relative max-sm:h-[80vh] max-lg:h-[65vh]">
      <h1 className="pb-[80px] text-white max-sm:text-[30px]">ALBUMS</h1>
      <Swiper
        breakpoints={{
          340: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          700: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
        }}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        modules={[FreeMode, Pagination, Navigation]}
        className="max-w-[90%] lg:max-w-[80%]"
      >
        {DiscographyData.map((item) => (
          <SwiperSlide key={item.title}>
            <div className="flex flex-col mx-auto gap-6 mb-20 group relative shadow-lg text-white rounded-xl px-6 py-8 h-[250px] w-[215px] lg:h-[300px] lg:w-[300px] overflow-hidden">
              <div
                className="absolute flex items-center inset-0 bg-cover bg-center"
                style={{ backgroundImage: `url(${item.backgroundImage})` }}
              >
                <div className="absolute inset-0 bg-black opacity-10 group-hover:opacity-80" />
                <div className="relative flex flex-col gap-3 items-center mx-auto">
                  <h1 className="text-xl lg:text-[16px] text-center">
                    {item.title}
                  </h1>
                  {/*  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <FaBookOpen />
                  </a> */}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className="swiper-button-prev"
        style={{
          position: "absolute",
          left: "100px", // Adjust this value to move the arrow closer to the carousel
          top: "50%",
          transform: "translateY(-50%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          color: "white",
          borderRadius: "50%",
          cursor: "pointer",
          zIndex: 10,
        }}
      >
        <IoIosArrowBack className="text-white text-3xl" />
      </div>
      <div
        className="swiper-button-next"
        style={{
          position: "absolute",
          right: "100px", // Adjust this value to move the arrow closer to the carousel
          top: "50%",
          transform: "translateY(-50%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          color: "white",
          borderRadius: "50%",
          cursor: "pointer",
          zIndex: 10,
        }}
      >
        <IoIosArrowForward className="text-white text-3xl" />
      </div>
      <div className="pt-5">
        <Link
          className="border p-3 hover:bg-white hover:text-black"
          to="/discography"
        >
          Voir Tout
        </Link>
      </div>
    </div>
  );
};

export default ActiveSlider;
