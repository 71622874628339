import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import ImgConcerts from "../../assets/concerts/bg-concerts1.webp";

const Concerts = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = ImgConcerts;
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <>
      <Navbar />
      <section
        className={`h-screen flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat max-sm:h-screen max-lg:h-screen overflow-y-auto transition-opacity duration-700 ${
          imageLoaded ? "opacity-100" : "opacity-0"
        }`}
        style={{ backgroundImage: `url(${ImgConcerts})` }}
      >
        <div className="pt-[150px] h-[100%] flex flex-col items-center mx-auto max-sm:pt-[115px]">
          <h1 className="text-white max-sm:text-[30px]">CONCERTS</h1>
          <div className="pt-[40px] text-justify text-white max-sm:max-w-[85%]">
            <p className="black-shadow max-w-[700px] pt-5 leading-9 mx-auto">
              Plus de 15 ans après avoir décroché des prix majeurs dans des
              compétitions internationales de premier plan, Cécile Ousset est
              devenue une favorite des chefs d’orchestre de toutes les
              générations.
              <br /> Elle a joué avec tous les grands orchestres et festivals
              britanniques, y compris plusieurs apparitions à la BBC Proms,
              Londres.
            </p>

            <div className="pt-[90px] h-screen flex flex-col items-center">
              <h2 className="text-[30px] max-sm:text-[20px] text-center">
                Collaborations chefs d'orchestre
              </h2>
              <ul className="black-shadow list-disc p-5 mb-10">
                <li>Simon Rattle</li>
                <li>Kurt Masur</li>
                <li>Günther Herbig</li>
                <li>Neville Marriner</li>
                <li>William Glock</li>
                <li>John Drummond</li>
                <li>Rudolf Barshai</li>
              </ul>

              <h2 className="text-[30px] max-sm:text-[20px] text-center">
                Moments marquants
              </h2>
              <ul className="black-shadow list-disc max-w-[700px] p-5 mb-10 max-sm:text-start">
                <li>
                  {" "}
                  <strong>1978</strong> : Concert avec l’Orchestre National de
                  France dirigé par Kurt Sanderling, suivie d'une tournée en
                  Europe.
                </li>
                <li>
                  {" "}
                  <strong>1980</strong> : Remplacement de Martha Argerich au
                  Festival d’Édimbourg, Écosse, ouvrant les portes du
                  Royaume-Uni.
                </li>
                <li>
                  {" "}
                  <strong>1982</strong> : Concert symphonique au Royal Festival
                  Hall, Londres, avec le Concerto pour piano n°2 de Brahms sous
                  la direction de Kurt Masur.
                </li>
                <li>
                  <strong>BBC Proms</strong> : Participations en 1982, 1983,
                  1987, 1988, 1990, 1993, jouant des œuvres de Dukas, Ravel,
                  Saint-Saëns, Franck, Chausson, Debussy, Poulenc, Fauré,
                  Bruckner, Mahler, Schumann.
                </li>
              </ul>

              <h2 className="text-white text-[30px] max-sm:text-[20px]">
                Orchestres européens notables
              </h2>
              <ul className="black-shadow text-white list-disc p-5 mb-10 max-sm:text-start">
                <li>Leipzig Gewandhaus Orchestra</li>
                <li>Bonn Beethovenhalle Orchestra</li>
                <li>RSO Berlin</li>
                <li>Rotterdam Philharmonic</li>
                <li>Swedish Chamber Orchestra</li>
                <li>Goteborg Symphony</li>
                <li>Bergen Philharmonic</li>
                <li>Monte Carlo Philharmonic</li>
                <li>Orchestre National de Bordeaux</li>
                <li>Royal Philharmonic</li>
                <li>BBC National Orchestra of Wales</li>
                <li>Northern Sinfonia</li>
                <li>Bournemouth Symphony</li>
              </ul>

              <h2 className="text-[30px] max-sm:text-[20px] text-center">
                Citations et réflexions
              </h2>
              <div className="black-shadow max-w-[700px] ">
                <p className="text-white pt-5">
                  Dans les années 1980, dans un "Great Contemporary pianists
                  speak for themselves", d'Elyse Mach (Dovder Publications, New
                  York), Cécile Ousset répondait ainsi :
                </p>
                <p className="text-white italic pt-5">
                  "Mon répertoire a changé, car aujourd'hui la demande porte sur
                  un grand répertoire romantique, mais ce n'est pas mon choix.
                  Il se trouve que j'ai beaucoup de succès en jouant
                  Rachmaninov, Tchaïkovski et d'autres œuvres du même genre.
                  Mais ce n'est pas mon choix. Je préfère Schumann. J'aime
                  Schumann et, bien sûr, Chopin. J'aime particulièrement le
                  concerto en la mineur de Schumann. J'aime aussi les deux
                  concertos de Brahms, et je joue beaucoup de Brahms maintenant.
                  De temps en temps, je joue le Troisième de Rachmaninov, mais
                  pour une femme, c'est vraiment difficile, et c'est
                  probablement la raison pour laquelle nous ne le jouons pas
                  beaucoup. Mais c'est le défi ultime. Il faut une grande main à
                  cause des grandes octaves, ce qui rend la chose difficile pour
                  une femme. Mais je ne pense pas qu'il soit plus difficile pour
                  une femme que pour un homme de résister à la fatigue de jouer
                  de telles œuvres. Si vous développez la technique pour
                  l'œuvre, il ne devrait pas y avoir de problème ; en tout cas,
                  ce n'est pas le cas pour moi. Et je n'ai pas d'exercices
                  particuliers pour développer cette technique. Je sais qu'on me
                  dit que j'ai un "gros son", mais il y a une différence entre
                  un gros son et un son dur. Pour une femme artiste, la
                  difficulté réside dans cette tendance à jouer sans puissance,
                  mais ce que l'on entend alors est un son dur, pas un son fort,
                  parce que le son n'est pas obtenu avec le poids venant de
                  l'épaule. Dans une certaine mesure, c'est ce qui donne à la
                  musique pour piano son énorme son ; c'est la technique
                  d'appariement."
                </p>
                <p className="text-white pt-5">
                  Le volumineux répertoire interprété et enregistré pour DECCA
                  que pour EMI par Cécile Ousset entre 1982 et 1990 n’a pas
                  nécessairement été celui qu’elle voulait jouer, mais ce que le
                  public voulait entendre.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Concerts;
