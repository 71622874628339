import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const TermsOfUse = () => {
  return (
    <>
      <Navbar />
      <section className="section bg-black h-[100%]" id="biography">
        <div className="pt-[60px] flex flex-col items-center mx-auto">
          <h1 className="text-white max-sm:text-[20px] text-[30px] max-lg:text-[28px]">
            MENTIONS LÉGALES
          </h1>
          <div className="pt-[70px] text-justify text-black">
            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto max-lg:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5 max-sm:text-[20px]">
                1. Édition du site
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 max-sm:text-start">
                Le site <strong>Cécile Ousset</strong> est édité par Thomas
                Chazal.
                <br />
                contact :{" "}
                <a href="mailto:thomaschazalpro@gmail.com">
                  thomaschazalpro@gmail.com
                </a>
              </p>
            </div>

            <div className="max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold text-white font-primary text-[25px] pt-10 pb-5 max-sm:text-[20px]">
                2. Hébergement
              </h4>
              <p className="max-w-[700px]  text-white pt-5 leading-9 max-sm:text-start">
                Le site est hébergé par :
                <br />
                <strong>Netlify, Inc.</strong>
                <br />
                Adresse : 2325 3rd Street, Suite 215, San Francisco, CA 94107
                (USA)
              </p>
            </div>

            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5 max-sm:text-[20px] max-sm:text-start">
                3. Directeur de la publication
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 leading-9  max-sm:text-start">
                Directeur de la publication : <strong>Thomas Chazal</strong>
                <br />
                Contact :{" "}
                <a href="mailto:thomaschazalpro@gmail.com">
                  thomaschazalpro@gmail.com
                </a>
              </p>
            </div>

            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5  max-sm:text-[20px] max-sm:text-start">
                4. Responsable de la rédaction
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 leading-9  max-sm:text-start">
                Responsable de la rédaction : <strong>Thomas Chazal</strong>
                <br />
                Contact :{" "}
                <a href="thomaschazalpro@gmail.com">thomaschazalpro@gmail.com</a>
              </p>
            </div>

            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold text-white font-primary text-[25px] pt-10 pb-5 max-sm:text-[20px]">
                5. Développement
              </h4>

              <p className="max-w-[700px] text-justify text-white pt-5 max-sm:text-start">
                Le développement du site est assuré par :
                <br />
                <strong>Thomas Chazal</strong>
                <br />
                Contact :{" "}
                <a href="mailto:thomaschazalpro@gmail.com">
                  thomaschazalpro@gmail.com
                </a>
              </p>
            </div>

            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5 max-sm:text-[20px]">
                6. Propriété intellectuelle
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 max-sm:text-start">
                Tous les contenus présents sur le site{" "}
                <strong>Cécile Ousset</strong>, incluant, de façon non
                limitative, les graphismes, images, textes, vidéos, animations,
                sons, logos, gifs et icônes ainsi que leur mise en forme sont la
                propriété exclusive de Thomas Chazal à l'exception des marques,
                logos ou contenus appartenant à d'autres sociétés partenaires ou
                auteurs.
              </p>
            </div>
            <div className="flex flex-col  max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5 max-sm:text-[20px] max-sm:text-start">
                7. Protection des données personnelles
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 max-sm:text-start">
                Conformément à la loi « informatique et libertés », vous
                disposez d'un droit d'accès, de rectification, de modification
                et de suppression concernant les données qui vous concernent.<br />
                Vous pouvez exercer ce droit en envoyant un email à{" "}
                <a href="mailto:thomaschazalpro@gmail.com">
                  thomaschazalpro@gmail.com
                </a>
                .
              </p>
            </div>
            <div className="flex flex-col max-sm:max-w-[85%] max-sm:mx-auto">
              <h4 className="font-bold font-primary text-white text-[25px] pt-10 pb-5 max-sm:text-[20px]">
                8. Conditions d'utilisation
              </h4>
              <p className="max-w-[700px] text-justify text-white pt-5 max-sm:text-start">
                Le site accessible par les URL suivants :{" "}
                <a href="https://www.cecileousset.com">www.cecileousset.com</a>{" "}
                est exploité dans le respect de la législation française.
                L'utilisation de ce site est régie par les présentes conditions
                générales.<br /> En utilisant le site, vous reconnaissez avoir pris
                connaissance de ces conditions et les avoir acceptées.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TermsOfUse;
