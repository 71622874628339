import React from "react";
import bgVideo from "../../assets/about/bg-featured.jpg";
import PrincipaleVideo from "../../components/video/FeaturedVideo";

const Featured = () => {
  return (
    <section
      className="flex flex-col justify-center items-center h-screen bg-cover bg-center bg-no-repeat max-sm:h-[50vh] max-lg:h-[65vh]"
      style={{ backgroundImage: `url(${bgVideo})` }}
    >
      <h1 className="pb-[80px] max-sm:text-[30px] max-sm:pb-[50px]">
        FEATURED VIDEO
      </h1>
      <PrincipaleVideo />
    </section>
  );
};

export default Featured;
