import Navbar from "../../components/navbar/Navbar";
import { DiscoData } from "../../data";
import Footer from "../../components/footer/Footer";
import { FaBookOpen } from "react-icons/fa";

const Discography = () => {
  // Fonction pour empêcher les actions de copier, coller et glisser-déposer
  const preventDefaultActions = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Navbar />
      <section className="overflow-hidden bg-clear">
        <div className="pt-[100px]">
          {DiscoData.map((item) => (
            <div
              className="flex-row-reverse flex justify-around gap-12 p-12 max-sm:flex-col"
              key={item.id}
            >
              <div className="flex flex-col items-center">
                <div className="flex gap-5">
                  <img
                    className="w-[300px] h-auto"
                    src={item.image}
                    alt="picturesalbums"
                    onContextMenu={preventDefaultActions} // Empêche le clic droit
                    onDragStart={preventDefaultActions} // Empêche le glisser-déposer
                    onMouseDown={preventDefaultActions} // Empêche d'autres actions de souris potentiellement indésirables
                  />
                  <div key={item.id} className="flex flex-col">
                    {item.id !== 21 && item.id !== 17 && (
                      <a
                        className="cursor-pointer"
                        href={item.pdfLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaBookOpen className="text-black" size={20} />
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <p className="max-w-[600px] font-oswald leading-9 text-[18px] text-justify">
                  <h3 className="font-oswald text-[20px] text-black font-medium max-sm:text-start">
                    {item.title}
                  </h3>
                  <p className="text-black pt-2 pb-2 max-sm:text-start">
                    {item.subtitle}
                  </p>
                  {/* BORDER */}
                  <div className="border border-black w-[600px] max-lg:max-w-[350px] max-sm:max-w-[100%]"></div>
                  <div className="flex space-x-4">
                    <p className="text-black">{item.ref}</p>
                    <div className="border border-black border-x-1"></div>
                    <p className="text-black">{item.num}</p>
                  </div>
                  <div className="border border-black w-[600px] max-lg:max-w-[350px] max-sm:max-w-[100%]"></div>

                  <p className="text-black pt-2 max-sm:text-start">
                    {item.content}
                  </p>
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Discography;
