import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";
import HeroImg from "../../assets/hero/photo3.jpeg";

const Hero = () => {
  const [animationPlayed, setAnimationPlayed] = useState(false);

  useEffect(() => {
    setAnimationPlayed(true); // Marque l'animation comme jouée une fois que le composant est monté
  }, []);

  // Fonction pour empêcher les actions de copier, coller et glisser-déposer
  const preventDefaultActions = (e) => {
    e.preventDefault();
  };

  return (
    <section
      className="section bg-black min-h-screen flex max-lg:flex-col max-sm:flex-col items-center max-sm:min-h-full max-lg:min-h-full"
      id="hero"
    >
      <motion.div
        variants={fadeIn("right", 0.2)}
        initial="hidden"
        animate={animationPlayed ? "show" : "hidden"} // Utilisation de l'état pour déterminer l'animation
        className="flex justify-center max-sm:pt-[90px] w-full"
      >
        <img
          src={HeroImg}
          alt=""
          className="max-h-[70vh] object-cover w-full max-lg:max-w-[100%]"
          onContextMenu={preventDefaultActions} // Empêche le clic droit
          onDragStart={preventDefaultActions} // Empêche le glisser-déposer
          onMouseDown={preventDefaultActions} // Empêche d'autres actions de souris potentiellement indésirables
        />
      </motion.div>
      <motion.div
        variants={fadeIn("left", 0.1)}
        initial="hidden"
        animate={animationPlayed ? "show" : "hidden"} // Utilisation de l'état pour déterminer l'animation
        className="flex flex-col justify-center items-center gap-y-8 pt-10 w-full"
      >
        <h1 className="leading-none max-sm:text-[40px] text-white text-center">
          CÉCILE OUSSET
        </h1>
        <h2 className="text-[25px] max-sm:text-[20px] text-white text-center">
          PIANISTE - CONCERTISTE
        </h2>
      </motion.div>
    </section>
  );
};

export default Hero;
